import { IconPlus } from "@tabler/icons-react";
import { Button } from "@mantine/core";

import SellOnPercent from "../SellOnPercent";
import uniqid from "uniqid";

function SellSetLong({ symbol, strategy, updateStrategy, removeStrategy }) {}

SellSetLong.type = "sell";
SellSetLong.isSetCreator = true;
SellSetLong.model = {
  name: "Sell Set Long",
};
SellSetLong.Button = function (props) {
  return (
    <Button variant="default" color="pink" leftIcon={<IconPlus />} {...props}>
      Long 3,5,7..
    </Button>
  );
};
SellSetLong.addSet = function () {
  return [
    // up
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 3, sellAmount: 6 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 5, sellAmount: 10 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 7, sellAmount: 14 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 10, sellAmount: 20 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 15, sellAmount: 15 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 20, sellAmount: 20 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 25, sellAmount: 25 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 50, sellAmount: 50 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 75, sellAmount: 75 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, up: 100, sellAmount: 100 },
    },
    // down
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: {
        ...SellOnPercent.model.params,
        base: "ceil",
        down: -10,
        sellAmount: 30,
      },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: {
        ...SellOnPercent.model.params,
        base: "ceil",
        down: -15,
        sellAmount: 100,
      },
    },
  ];
};

export default SellSetLong;
