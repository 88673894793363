import { IconPlus } from "@tabler/icons-react";
import { Button } from "@mantine/core";

import SellOnPercent from "../SellOnPercent";
import uniqid from "uniqid";

function SellSetShort({ symbol, strategy, updateStrategy, removeStrategy }) {}

SellSetShort.type = "sell";
SellSetShort.isSetCreator = true;
SellSetShort.model = {
  name: "Sell Set Short",
};
SellSetShort.Button = function (props) {
  return (
    <Button variant="default" color="pink" leftIcon={<IconPlus />} {...props}>
      Short 3,5,7..
    </Button>
  );
};
SellSetShort.addSet = function () {
  return [
    // down
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -3, sellAmount: 6 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -5, sellAmount: 10 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -7, sellAmount: 14 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -10, sellAmount: 20 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -15, sellAmount: 15 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -20, sellAmount: 20 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -25, sellAmount: 25 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -30, sellAmount: 50 },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: { ...SellOnPercent.model.params, down: -50, sellAmount: 100 },
    },
    // up
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: {
        ...SellOnPercent.model.params,
        base: "bottom",
        up: 7.5,
        sellAmount: 30,
      },
    },
    {
      id: uniqid(),
      ...SellOnPercent.model,
      params: {
        ...SellOnPercent.model.params,
        base: "bottom",
        up: 10,
        sellAmount: 100,
      },
    },
  ];
};

export default SellSetShort;
