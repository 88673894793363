import { IconPlus, IconX } from "@tabler/icons-react";
import { Text, Button, Grid, SegmentedControl, Switch } from "@mantine/core";
import AmountPercentPicker from "../AmountPercentPicker";

function OnClose({ strategy, updateStrategy, removeStrategy }) {
  return (
    <Grid>
      <Grid.Col
        xl={3}
        lg={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          // color="violet"
          onClick={() => removeStrategy(strategy)}
        >
          On Close
        </Button>
      </Grid.Col>
      <Grid.Col xl={9} lg={12}>
        <Grid>
          <Grid.Col xl={8} lg={12}>
            <SegmentedControl
              value={strategy.params.interval}
              onChange={(value) =>
                updateStrategy({
                  ...strategy,
                  params: {
                    ...strategy.params,
                    interval: value,
                    setTime: new Date(),
                  },
                })
              }
              data={[
                { value: "1m", label: "1m" },
                { value: "3m", label: "3m" },
                { value: "5m", label: "5m" },
                { value: "15m", label: "15m" },
                { value: "1h", label: "1h" },
                { value: "4h", label: "4h" },
                { value: "1d", label: "1d" },
                { value: "1w", label: "1w" },
              ]}
            />
          </Grid.Col>
          <Grid.Col
            xl={2}
            lg={12}
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <AmountPercentPicker
              value={strategy.params.sellAmount}
              onChange={(value) => {
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, sellAmount: value },
                });
              }}
            />
          </Grid.Col>
          <Grid.Col
            xl={2}
            lg={12}
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "flex-end",
              paddingBottom: "1rem",
            }}
          >
            <Switch
              label={<b>AND</b>}
              checked={strategy.params.and}
              onClick={(e) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, and: e.target.checked },
                })
              }
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

OnClose.type = "sell";
OnClose.color = "violet";
OnClose.model = {
  name: "on_next_close",
  title: "On Close",
  // axlorithm: "flex_cross",
  params: {
    interval: "1m",
    setTime: 0,
    sellAmount: 100,
  },
};
OnClose.paramsTitle = (strategy) => {
  return `${strategy.params.interval} | ${strategy.params.sellAmount || 100}%`;
};

OnClose.Button = function (props) {
  return (
    <Button variant="default" color="violet" leftIcon={<IconPlus />} {...props}>
      On Close
    </Button>
  );
};

export default OnClose;
