import { useSelector } from "react-redux";
import { toFixed, toFixedMeaningful, toK } from "../../common/helpers";
import { Grid, Text, Tooltip } from "@mantine/core";

import Symbol from "./Symbol";
import Status from "./Status";
import Summary from "./Summary";

/* BuyFields is used when order has status "buy" or order is stopped on "buy" status*/
export default function BuyFields({ order, isActive, isStopped, isExecuted }) {
  const currentPrice = useSelector((state) => state.currentPrice[order.symbol]);

  let buyAmount, buyPureAmount, buyPrice, invested;
  let pureSymbol = order.symbol.replace("USDT", "");

  if (order.amount) {
    buyAmount = `Buy: ${toK(order.amount)} ${pureSymbol} (${
      currentPrice ? toFixed(order.amount * currentPrice, 0) : 0
    }$)`;
    buyPureAmount = `${order.amount} ${pureSymbol}`;
  } else if (order.amountUsd) {
    buyAmount = `Buy: ${order.amountUsd}$ (${
      currentPrice
        ? toK(toFixedMeaningful(order.amountUsd / currentPrice, 4))
        : 0
    } ${pureSymbol})`;
    buyPureAmount = `${order.amountUsd / currentPrice} ${pureSymbol}`;
  }

  buyPrice = `Curr. price: ${currentPrice}`;

  invested = toFixed(
    order.ops?.reduce((sum, op) => {
      return op.action === "buy" ? sum + op.amountUsd : sum;
    }, 0) || 0,
    0
  );

  return (
    <Grid>
      <Grid.Col lg={4}>
        <Grid>
          <Grid.Col lg={12}>
            <Symbol order={order} />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Status order={order} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={4}>
        <Grid>
          <Grid.Col lg={12}>
            <Tooltip label={buyPureAmount}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {buyAmount}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12}>
            <Text align="center" fw={700} size="sm">
              {buyPrice}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={4}>
        <Grid>
          <Grid.Col lg={12}>
            {order.market === "futures" ? (
              <Text align="center" fw={700} size="sm">
                Total:{" "}
                {toK(toFixed(invested / order.futuresStrategy?.leverage, 0), 1)}
                $ * {order.futuresStrategy?.leverage} ={" "}
                {toK(toFixed(invested, 0), 1)}$
              </Text>
            ) : (
              <Text align="center" fw={700} size="sm">
                Invested: {toK(toFixed(invested, 0), 1)}$
              </Text>
            )}
          </Grid.Col>
          <Grid.Col lg={12}>
            <Summary order={order} isFake={order.isFake} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}
