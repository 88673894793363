import { useSelector } from "react-redux";
import { toFixed, toFixedMeaningful, toK } from "../../common/helpers";
import { Grid, Text, Tooltip } from "@mantine/core";

import Symbol from "./Symbol";
import Status from "./Status";

/* SingleSellFields is used when order has status "sell" or order is stopped on "sell" status or order is executed & start action is sell*/
export default function SingleSellFields({ order, isActive, isExecuted }) {
  let sellAmount, sellPrice, pureSellAmount;
  let pureSymbol = order.symbol.replace("USDT", "");

  const currentPrice = useSelector((state) => state.currentPrice[order.symbol]);

  if (isExecuted) {
    pureSellAmount = `${order.sell.amount} ${pureSymbol}`;
    sellAmount = `Sold: ${toK(order.sell.amount)} ${pureSymbol} (${toFixed(
      order.sell.amountUsd,
      1
    )}$)`;

    sellPrice = `Sell price: ${toFixedMeaningful(order.sell?.price, 4)}`;
  } else {
    if (order.amount) {
      pureSellAmount = `${order.amount} ${pureSymbol}`;
      sellAmount = `Sell: ${toK(order.amount)} = ${toFixed(
        order.amount * currentPrice,
        1
      )} usd`;
    } else if (order.amountUsd) {
      pureSellAmount = `${order.amountUsd / currentPrice} ${pureSymbol}`;
      sellAmount = `Sell: ${order.amountUsd} usd`;
    } else if (order.amountPercent) {
      pureSellAmount = "Not available";
      sellAmount = `Sell: ${order.amountPercent} %`;
    }

    sellPrice = `Curr. price: ${currentPrice}`;
  }

  return (
    <Grid>
      <Grid.Col lg={6}>
        <Grid>
          <Grid.Col lg={12}>
            <Symbol order={order} />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Status order={order} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={6}>
        <Grid>
          <Grid.Col lg={12}>
            <Tooltip label={pureSellAmount}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {sellAmount}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12}>
            <Text align="center" fw={700} size="sm">
              {sellPrice}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}
