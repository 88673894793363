import { useMemo } from "react";
import { Badge, Box, Tooltip, Text } from "@mantine/core";
import { IconReload, IconRobot } from "@tabler/icons-react";

export default function Status({ order }) {
  const { symbol, isFake } = order;

  const sellTimes = useMemo(() => {
    return order.ops?.reduce((result, op) => {
      return op.action === "sell" && op.sellAmount === 100
        ? result + 1
        : result;
    }, 0);
  }, [order.ops?.length]);

  const isCreatedByBot = order.source === "bot";

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      {isCreatedByBot && (
        <Tooltip label={order.creatorId}>
          <Badge
            ml="0.5rem"
            color={isFake ? "gray.9" : "teal.9"}
            style={{ cursor: "pointer" }}
          >
            <IconRobot
              style={{
                display: "flex",
                alignItems: "center",
                width: "0.8rem",
                height: "0.8rem",
              }}
            />
          </Badge>
        </Tooltip>
      )}
      {sellTimes >= 0 && (
        <Badge
          ml="0.5rem"
          color={isFake ? "gray.9" : "teal.9"}
          leftSection={
            <IconReload
              style={{
                display: "flex",
                alignItems: "center",
                width: "0.8rem",
                height: "0.8rem",
              }}
            />
          }
        >
          {sellTimes}
        </Badge>
      )}
      {order.status === "buy" && (
        <Badge ml="0.5rem" color={order.isFake ? "gray.9" : "teal.9"}>
          BUY
        </Badge>
      )}
      {order.status === "sell" && (
        <Badge ml="0.5rem" color={order.isFake ? "gray.9" : "teal.9"}>
          SELL
        </Badge>
      )}
      {order.status === "stopped" && (
        <Badge ml="0.5rem" color={order.isFake ? "gray.9" : "teal.9"}>
          STOPPED
        </Badge>
      )}
      {order.status === "executed" && (
        <Tooltip label={`${new Date(order.sell?.time).toLocaleString()}`}>
          <Badge
            ml="0.5rem"
            color={order.isFake ? "gray.9" : "teal.9"}
            style={{ cursor: "pointer" }}
          >
            DONE
          </Badge>
        </Tooltip>
      )}
    </Box>
  );
}
