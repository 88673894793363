import { useEffect, useState } from "react";
import { ActionIcon, Box, Button, Grid, Group } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  IconEyeOff,
  IconArrowsExchange,
  IconInfoCircle,
} from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import { firstLetterToUppercase } from "../../common/helpers";

import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { CREATE_ORDER, createOrder } from "../../common/data/orders/redux";
import {
  subscribeToPrice,
  unsubscribeById,
} from "../../common/data/currentPrice/redux";

import SymbolPicker from "../SymbolPicker";
import AmountPicker from "../AmountStrategyPicker/AmountPicker";
import AmountUsdPicker from "../AmountStrategyPicker/AmountUsdPicker";
import { StrategyPickerModal, StrategyPickerBoard } from "../StrategyPicker";
import AmountPercentPicker from "../AmountStrategyPicker/AmountPercentPicker";
import { toFixed } from "../../common/helpers";
import { useMediaQuery } from "@mantine/hooks";
import {
  AmountStrategyBoardButton,
  AmountStrategyPickerModal,
  AmountStrategyInitModel,
} from "../AmountStrategyPicker";
import {
  FuturesStrategyBoardButton,
  FuturesStrategyInitModel,
  FuturesStrategyPickerModal,
} from "../FuturesStrategyPicker";
import { toggleStarred } from "../../common/data/starred/redux";
import StarButton, { PinButton } from "../StarButton";

const CreateOrderButton = ({
  symbol,
  market,
  type,
  action,
  position,
  amountStrategy,
  create,
}) => {
  let currentPrice = useSelector((state) => {
    return state.currentPrice[`${symbol}USDT`];
  });
  currentPrice = currentPrice || 0;

  const createOrderProcess = useSelector(
    (state) => state.processes[CREATE_ORDER]
  );

  let actionTitle = firstLetterToUppercase(
    market === "spot" ? action : position
  );
  let color =
    type === "fake"
      ? "gray"
      : market === "spot"
      ? action === "buy"
        ? "teal"
        : "red"
      : position === "long"
      ? "teal"
      : "red";

  return (
    <Button
      w="100%"
      variant="light"
      color={color}
      loading={createOrderProcess?.inProcess}
      disabled={createOrderProcess?.inProcess}
      onClick={create}
    >
      {actionTitle}{" "}
      {+amountStrategy.amountUsd
        ? `${amountStrategy.amountUsd}$ ${symbol.replace("USDT", "")}`
        : +amountStrategy.amountPercent
        ? `${amountStrategy.amountPercent}% ${symbol.replace("USDT", "")}`
        : +amountStrategy.amount
        ? `${amountStrategy.amount} ${symbol.replace("USDT", "")} = ${
            currentPrice
              ? toFixed(amountStrategy.amount * currentPrice, 0)
              : "_"
          }$`
        : null}
    </Button>
  );
};

export function NewOrderShort({
  initSymbol,
  initPlatform,
  hideForm,
  showStarButton = true,
}) {
  const dispatch = useDispatch();

  const [searchParams, _] = useSearchParams();

  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  // symbol & platform & action & type
  const [symbol, setSymbol] = useState(() => initSymbol || "BTC");
  const [debouncedSymbol] = useDebouncedValue(symbol, 300);
  const [platform, setPlatform] = useState(() => initPlatform || "binance");

  useEffect(() => {
    if (!debouncedSymbol || !platform) return;
    dispatch(unsubscribeById({ id: "new_order" }));
    dispatch(
      subscribeToPrice({
        symbol: `${debouncedSymbol}USDT`,
        id: "new_order",
        platform,
      })
    );
  }, [debouncedSymbol, platform]);

  const onChangeSymbol = (symbol) => {
    if (!symbol) {
      return;
    }

    setSymbol(symbol.toUpperCase());
  };

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <TradingViewAdvancedChartWidget
            symbol={`${symbol}USDT`}
            platform={platform.toUpperCase()}
            width="100%"
            height={isSmallerThanLarge ? "300px" : "300px"}
            interval="1d"
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col lg={3} md={3} sm={6} xs={6} span={6}>
              <SymbolPicker value={symbol} onChange={onChangeSymbol} />
            </Grid.Col>
            <Grid.Col lg={3} md={3} sm={6} xs={6} span={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Button
                  w="100%"
                  variant="white"
                  color="gray"
                  onClick={() =>
                    platform === "binance"
                      ? setPlatform("bybit")
                      : platform === "bybit"
                      ? setPlatform("mexc")
                      : setPlatform("binance")
                  }
                >
                  {platform}
                </Button>
              </Box>
            </Grid.Col>
            <Grid.Col lg={3} md={3} sm={6} xs={6} span={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  height: "100%",
                }}
              >
                <NavLink
                  to={`/trade?symbol=${symbol}&platform=${platform}`}
                  style={{ width: "100%" }}
                >
                  <Button w="100%" variant="light" color="teal">
                    Trade
                  </Button>
                </NavLink>
              </Box>
            </Grid.Col>
            {showStarButton && (
              <Grid.Col lg={3} md={3} sm={6} xs={6} span={6}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    paddingBottom: "0.5rem",
                    height: "100%",
                  }}
                >
                  <Group>
                    <StarButton symbol={symbol} platform={platform} />
                    <PinButton symbol={symbol} platform={platform} />
                    <ActionIcon
                      variant="transparent"
                      color="gray.3"
                      component="a"
                      href={`https://chatgpt.com/?q=${encodeURIComponent(
                        `Tell me about the crypto token with ticker ${symbol.replace(
                          "USDT",
                          ""
                        )} in two languages: first in Russian, then in English. Go point by point: 1. Give links to official website, Twitter, CoinMarketCap and Coingecko. 2. Provide last price, market capitalization and 24hr volume in usd (use short scale notation). 3. Provide a clear explanation of the project in simple words, including multiple real-world use cases demonstrating its purpose and functionality. 4. Provide an overview of the project's history, including information on its founders (background, previous projects), major investors and supporters, key milestones (funding rounds, partnerships, ecosystem expansions), and any significant changes in tokenomics or governance. 5. Compare project with at least three similar projects, highlighting its competitive advantages, unique features and potential weaknesses compared to others. 6. Provide a deep dive into token’s technology, utility, tokenomics and its market potential based on current trends and adoption. `
                      )}&hints=search`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconInfoCircle />
                    </ActionIcon>
                  </Group>
                </Box>
              </Grid.Col>
            )}
          </Grid>
        </Grid.Col>
      </Grid>
    </>
  );
}

export default function NewOrder({
  initSymbol,
  initPlatform,
  hideForm,
  showStarButton = true,
}) {
  const dispatch = useDispatch();

  const [searchParams, _] = useSearchParams();

  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  // symbol & platform & action & type
  const [symbol, setSymbol] = useState(
    () => initSymbol || searchParams.get("symbol") || "BTC"
  );
  const [debouncedSymbol] = useDebouncedValue(symbol, 300);
  const [platform, setPlatform] = useState(
    () => initPlatform || searchParams.get("platform") || "binance"
  );
  const [market, setMarket] = useState("spot");
  const [action, setAction] = useState(
    () => searchParams.get("action") || "buy"
  );
  const [position, setPosition] = useState(
    () => searchParams.get("position") || "long"
  );
  const [type, setType] = useState(() => searchParams.get("type") || "real");
  // amount strategy picker
  const [showAmountStrategyPicker, setShowAmountStrategyPicker] =
    useState(false);
  const [showFuturesStrategyPicker, setShowFuturesStrategyPicker] =
    useState(false);
  const [amountStrategy, setAmountStrategy] = useState({
    ...AmountStrategyInitModel,
  });
  const [futuresStrategy, setFuturesStrategy] = useState({
    ...FuturesStrategyInitModel,
  });
  // buy & sell & repeat strategy picker
  const [showStrategyPicker, setShowStrategyPicker] = useState(false);
  const [buyStrategies, setBuyStrategies] = useState([]);
  const [sellStrategies, setSellStrategies] = useState([]);
  const [repeatStrategies, setRepeatStrategies] = useState([]);

  // console.log(currentPrice);

  // binance price
  // const { lastMessage: binanceMessage } = useWebSocket(
  //   `wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@kline_1s`
  // );
  // const binanceData = binanceMessage && JSON.parse(binanceMessage.data);
  // const binancePrice = parseFloat(binanceData?.k?.c) || 0;
  // const currentPrice = platform === "binance" ? binancePrice : 0;

  useEffect(
    function updateSymbol() {
      if (searchParams.get("symbol") && !initSymbol) {
        let symbol = searchParams.get("symbol");

        setSymbol(symbol.replace("USDT", ""));
      }
    },
    [searchParams.get("symbol")]
  );

  useEffect(
    function updatePlatform() {
      if (searchParams.get("platform") && !initPlatform) {
        setPlatform(searchParams.get("platform"));
      }
    },
    [searchParams.get("platform")]
  );

  useEffect(
    function resetRepeatStrategies() {
      if (!buyStrategies.length && !sellStrategies.length) {
        setRepeatStrategies([]);
      }
    },
    [buyStrategies, sellStrategies]
  );

  useEffect(
    function resetStrategiesOnSingleSell() {
      if (market === "spot" && action === "sell") {
        setAmountStrategy({ ...AmountStrategyInitModel });
        setBuyStrategies([]);
        setRepeatStrategies([]);
      }
    },
    [market, action]
  );

  useEffect(() => {
    if (!debouncedSymbol || !platform) return;
    dispatch(unsubscribeById({ id: "new_order" }));
    dispatch(
      subscribeToPrice({
        symbol: `${debouncedSymbol}USDT`,
        id: "new_order",
        platform,
      })
    );
  }, [debouncedSymbol, platform]);

  const onChangeSymbol = (symbol) => {
    if (!symbol) {
      return;
    }

    setSymbol(symbol.toUpperCase());
  };

  const create = () => {
    dispatch(
      createOrder({
        symbol: `${symbol}USDT`,
        platform,
        market,
        position,
        action: market === "futures" ? "buy" : action,
        isFake: type === "fake",
        amountStrategy: amountStrategy,
        futuresStrategy: futuresStrategy,
        buyStrategies: buyStrategies,
        sellStrategies: sellStrategies,
        repeatStrategies: repeatStrategies,
      })
    );
  };

  return (
    <>
      <AmountStrategyPickerModal
        show={showAmountStrategyPicker}
        amountStrategy={amountStrategy}
        setAmountStrategy={setAmountStrategy}
        onClose={() => setShowAmountStrategyPicker(false)}
        onSubmit={() => setShowAmountStrategyPicker(false)}
        hideByVolume={market === "spot" && action === "sell"}
      ></AmountStrategyPickerModal>
      <FuturesStrategyPickerModal
        show={showFuturesStrategyPicker}
        futuresStrategy={futuresStrategy}
        setFuturesStrategy={setFuturesStrategy}
        onClose={() => setShowFuturesStrategyPicker(false)}
        onSubmit={() => setShowFuturesStrategyPicker(false)}
      ></FuturesStrategyPickerModal>
      <StrategyPickerModal
        symbol={symbol}
        show={showStrategyPicker}
        buyStrategies={buyStrategies}
        setBuyStrategies={setBuyStrategies}
        sellStrategies={sellStrategies}
        setSellStrategies={setSellStrategies}
        repeatStrategies={repeatStrategies}
        setRepeatStrategies={setRepeatStrategies}
        onClose={() => setShowStrategyPicker(false)}
        onSubmit={() => setShowStrategyPicker(false)}
        disableBuyStrategies={market === "spot" && action === "sell"}
        disableRepeatStrategies={market === "spot" && action === "sell"}
      ></StrategyPickerModal>
      <Grid>
        <Grid.Col span={12}>
          <TradingViewAdvancedChartWidget
            symbol={`${symbol}USDT`}
            platform={platform.toUpperCase()}
            market={market}
            width="100%"
            height={isSmallerThanLarge ? "300px" : "300px"}
            interval="1d"
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Box shadow="xs">
            <Grid>
              {/*<Grid.Col span={3}>*/}
              {/*  <Box*/}
              {/*    sx={{*/}
              {/*      display: "flex",*/}
              {/*      alignItems: "flex-end",*/}
              {/*      height: "100%",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <Button*/}
              {/*      w="100%"*/}
              {/*      variant="white"*/}
              {/*      color="gray"*/}
              {/*      onClick={hideForm}*/}
              {/*    >*/}
              {/*      <IconEyeOff />*/}
              {/*    </Button>*/}
              {/*  </Box>*/}
              {/*</Grid.Col>*/}
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() =>
                      platform === "binance"
                        ? setPlatform("bybit")
                        : platform === "bybit"
                        ? setPlatform("mexc")
                        : setPlatform("binance")
                    }
                  >
                    {platform}
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() =>
                      market === "spot"
                        ? setMarket("futures")
                        : setMarket("spot")
                    }
                  >
                    {market}
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() => {
                      setAction((action) =>
                        action === "buy" ? "sell" : "buy"
                      );
                      setPosition((position) =>
                        position === "long" ? "short" : "long"
                      );
                    }}
                  >
                    <IconArrowsExchange />
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col span={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <Button
                    w="100%"
                    variant="white"
                    color="gray"
                    onClick={() =>
                      setType((type) => (type === "real" ? "fake" : "real"))
                    }
                  >
                    {
                      type === "real"
                        ? "real"
                        : // <IconMasksTheaterOff />
                          "fake"
                      // <IconMasksTheater />
                    }
                  </Button>
                </Box>
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <SymbolPicker value={symbol} onChange={onChangeSymbol} />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountUsdPicker
                  amountStrategy={amountStrategy}
                  setAmountStrategy={setAmountStrategy}
                />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountPicker
                  amountStrategy={amountStrategy}
                  setAmountStrategy={setAmountStrategy}
                />
              </Grid.Col>
              <Grid.Col xl={2} lg={3} md={6} sm={6} xs={6} span={6}>
                <AmountPercentPicker
                  amountStrategy={amountStrategy}
                  setAmountStrategy={setAmountStrategy}
                />
              </Grid.Col>
              <Grid.Col xl={4} lg={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <CreateOrderButton
                    symbol={symbol}
                    market={market}
                    type={type}
                    action={action}
                    position={position}
                    amountStrategy={amountStrategy}
                    create={create}
                  />
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
        <Grid.Col span={12}>
          <Group
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {market === "futures" && (
              <FuturesStrategyBoardButton
                symbol={symbol}
                position={position}
                futuresStrategy={futuresStrategy}
                openModal={() => setShowFuturesStrategyPicker(true)}
                isFake={type === "fake"}
              />
            )}
            <AmountStrategyBoardButton
              symbol={symbol}
              amountStrategy={amountStrategy}
              openModal={() => setShowAmountStrategyPicker(true)}
              isFake={type === "fake"}
            />
            <StrategyPickerBoard
              buyStrategies={buyStrategies}
              sellStrategies={sellStrategies}
              repeatStrategies={repeatStrategies}
              openModal={() => setShowStrategyPicker(true)}
              isFake={type === "fake"}
            />
            {showStarButton && (
              <StarButton symbol={symbol} platform={platform} />
            )}
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
}
