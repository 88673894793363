import { useState } from "react";
import { Select, Text } from "@mantine/core";

export default function AmountPercentPicker({
  value,
  onChange,
  initialOptions,
  ...props
}) {
  const [options, setOptions] = useState(() => {
    initialOptions = initialOptions || [
      "1",
      "3",
      "5",
      "7",
      "10",
      "15",
      "20",
      "25",
      "30",
      "50",
      "75",
      "100",
    ];
    if (!initialOptions.includes(String(value))) {
      initialOptions.push(String(value));
      initialOptions.sort((a, b) => a - b);
    }
    return initialOptions;
  });

  return (
    <Select
      // label={<Text fw={700}>Amount (%)</Text>}
      placeholder="Amount (%)"
      maxDropdownHeight={400}
      data={options}
      value={String(value)}
      onChange={(value) => {
        onChange(Number(value));
      }}
      {...props}
      withinPortal={true}
      searchable={true}
      creatable={true}
      getCreateLabel={(query) => `+ Create ${query}`}
      onCreate={(query) => {
        setOptions((current) => {
          let updatedOptions = [...current, String(query)];
          console.log(updatedOptions);
          updatedOptions.sort((a, b) => a - b);
          return updatedOptions;
        });
        return query;
      }}
    />
  );
}
