import { IconStarFilled, IconStar, IconPin } from "@tabler/icons-react";
import { ActionIcon } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";

import { toggleStarred } from "../common/data/starred/redux";

export function PinButton({ symbol, platform }) {
  const dispatch = useDispatch();
  const starred = useSelector((state) => state.starred);
  const onlySymbol = symbol.replace("USDT", "");
  const isStarred = !!starred?.tokens?.[onlySymbol];

  return (
    <ActionIcon
      variant="transparent"
      color="gray.4"
      onClick={() => {
        dispatch(
          toggleStarred({
            symbol: onlySymbol,
            platform: platform,
            value: false,
          })
        );
      }}
    >
      <IconPin />
    </ActionIcon>
  );
}

export default function StarButton({ symbol, platform }) {
  const dispatch = useDispatch();
  const starred = useSelector((state) => state.starred);
  const onlySymbol = symbol.replace("USDT", "");
  const isStarred = !!starred?.tokens?.[onlySymbol];

  return (
    <ActionIcon
      variant="transparent"
      color={isStarred ? "teal.7" : "gray.2"}
      onClick={() => {
        dispatch(
          toggleStarred({
            symbol: onlySymbol,
            platform: platform,
            value: isStarred,
          })
        );
      }}
    >
      {isStarred ? <IconStar /> : <IconStarFilled />}
    </ActionIcon>
  );
}
