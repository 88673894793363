import { Fragment, React } from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Group,
  Modal,
  SegmentedControl,
  Text,
  UnstyledButton,
  Center,
} from "@mantine/core";
import { IconMathFunction } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

export const FuturesStrategyInitModel = {
  mode: "hedge",
  margin: "isolated",
  leverage: 1,
};

export const FuturesStrategyBoardButton = ({
  symbol,
  position,
  futuresStrategy,
  openModal,
  isFake,
  disableButtons,
}) => {
  let desc = "-";
  let { mode, margin, leverage } = futuresStrategy || {};
  leverage = Number(leverage);

  return (
    <Fragment>
      <UnstyledButton
        key="amount"
        onClick={openModal}
        disabled={disableButtons}
      >
        <Group>
          <Avatar
            size={40}
            color={isFake ? "gray" : position === "short" ? "red" : "teal"}
          >
            <IconMathFunction />
          </Avatar>
          <div>
            <Text fw={700} size="sm">
              Futures
            </Text>
            <Text size="sm">
              {position} {leverage}x
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    </Fragment>
  );
};

export const FuturesStrategyPickerModal = ({
  show,
  futuresStrategy,
  setFuturesStrategy,
  onClose,
  onSubmit,
  process,
}) => {
  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  return (
    <Modal
      opened={show}
      onClose={onClose}
      centered={true}
      withCloseButton={false}
      size={isSmallerThanSmall ? "100%" : isSmallerThanLarge ? "75%" : "50%"}
      trapFocus={false}
      transitionProps={{ duration: 0 }}
      lockScroll={false}
    >
      <Grid>
        <Grid.Col span={12}>
          <Divider size="lg" label={<b>Mode</b>} labelPosition="center" />
        </Grid.Col>
        <Grid.Col span={12}>
          <Center>
            <Text>
              <b>{futuresStrategy.mode.toUpperCase()}</b> mode is set
              automatically for all orders
            </Text>
          </Center>
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider size="lg" label={<b>Margin</b>} labelPosition="center" />
        </Grid.Col>
        <Grid.Col span={12}>
          <Center>
            <Text>
              <b>{futuresStrategy.margin.toUpperCase()}</b> margin is set
              automatically for all orders
            </Text>
          </Center>
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider size="lg" label={<b>Leverage</b>} labelPosition="center" />
        </Grid.Col>
        <Grid.Col lg={12}>
          <Center>
            <SegmentedControl
              value={String(futuresStrategy.leverage)}
              onChange={(value) =>
                setFuturesStrategy({
                  ...futuresStrategy,
                  leverage: Number(value),
                })
              }
              data={[
                { value: "1", label: "1x" },
                { value: "2", label: "2x" },
                { value: "3", label: "3x" },
                { value: "4", label: "4x" },
                { value: "5", label: "5x" },
                { value: "6", label: "6x" },
                { value: "7", label: "7x" },
                { value: "8", label: "8x" },
                { value: "9", label: "9x" },
                { value: "10", label: "10x" },
              ]}
            />
          </Center>
        </Grid.Col>
        <Grid.Col span={6}>
          <Button
            fullWidth
            variant="light"
            color="blue"
            onClick={onSubmit}
            loading={process?.inProcess}
            disabled={process?.inProcess}
          >
            Submit
          </Button>
        </Grid.Col>
        <Grid.Col span={6}>
          <Button fullWidth variant="white" color="gray" onClick={onClose}>
            Close
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};
