import { IconPlus, IconX } from "@tabler/icons-react";
import { Button, Grid, SegmentedControl } from "@mantine/core";

function Repeat({ strategy, updateStrategy, removeStrategy }) {
  return (
    <Grid>
      <Grid.Col
        xl={3}
        lg={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          onClick={() => removeStrategy(strategy)}
        >
          Repeat
        </Button>
      </Grid.Col>
      <Grid.Col lg={8}>
        <SegmentedControl
          value={strategy.params.repeat}
          onChange={(value) =>
            updateStrategy({
              ...strategy,
              params: { ...strategy.params, repeat: value },
            })
          }
          data={[
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "10", label: "10" },
            { value: "25", label: "25" },
            { value: "50", label: "50" },
            { value: "75", label: "75" },
            { value: "100", label: "100" },
            { value: "hold", label: "hold" },
          ]}
        />
      </Grid.Col>
    </Grid>
  );
}

Repeat.type = "repeat";
Repeat.color = "violet";
Repeat.model = {
  name: "repeat",
  title: "Repeat",
  params: {
    repeat: "2",
  },
};
Repeat.paramsTitle = (strategy) => {
  return strategy.params.repeat;
};
Repeat.Button = function (props) {
  return (
    <Button variant="default" color="violet" leftIcon={<IconPlus />} {...props}>
      Repeat
    </Button>
  );
};

export default Repeat;
