import React, { useState, useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconPlus } from "@tabler/icons-react";
import {
  Button,
  Grid,
  Center,
  Group,
  Text,
  Alert,
  Select,
  TextInput,
  Divider,
  Tooltip,
} from "@mantine/core";

import { watchOrders } from "../common/data/orders/redux";
import { WATCH_PRICES } from "../common/data/currentPrice/redux";
import Mongo from "../common/sevices/mongo";

import NewOrder from "./NewOrder";
import Order from "./Order";

function BasicStats({ ordersLength, profitPercent, profit }) {
  return (
    <Group
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1.5rem",
      }}
    >
      <Text size="sm" span={true}>
        <b>{ordersLength} order(s) </b>
      </Text>
      <Tooltip label="Only fixed profits are counted">
        <Text
          size="sm"
          span={true}
          style={{ cursor: "pointer" }}
          color={
            profitPercent === 0 ? "dark" : profitPercent > 0 ? "teal" : "red"
          }
        >
          <b>{profitPercent > 0 ? `+${profitPercent}` : profitPercent}%</b>
        </Text>
      </Tooltip>
      <Tooltip label="Only fixed profits are counted">
        <Text
          size="sm"
          span={true}
          style={{ cursor: "pointer" }}
          color={profit === 0 ? "dark" : profit > 0 ? "teal" : "red"}
        >
          <b>{profit > 0 ? `+${profit}` : profit}$</b>
        </Text>
      </Tooltip>
    </Group>
  );
}

const TrendIndexWarning = () => {
  const [ti, setTi] = useState(null);

  useEffect(function getTrendIndexes() {
    const get = async () => {
      try {
        if (!Mongo.client) await Mongo.connect();

        let [w1, d1, h4] = await Promise.all([
          Mongo.client
            .db(Mongo.dbs.main)
            .collection(`trendIndex_1w`)
            .find({}, { sort: { _id: -1 }, limit: 1 }),
          Mongo.client
            .db(Mongo.dbs.main)
            .collection(`trendIndex_1d`)
            .find({}, { sort: { _id: -1 }, limit: 1 }),
          Mongo.client
            .db(Mongo.dbs.main)
            .collection(`trendIndex_4h`)
            .find({}, { sort: { _id: -1 }, limit: 1 }),
        ]);

        // console.log(w1, d1, h4);

        setTi({
          "1w": w1[0]?.gisFisStats?.trendIndex,
          "1d": d1[0]?.gisFisStats?.trendIndex,
          "4h": h4[0]?.gisFisStats?.trendIndex,
        });
      } catch (e) {
        console.log(e);
      }
    };

    get();
  }, []);

  if (!ti) return <div>...</div>;
  else
    return (
      <>
        <Grid>
          {ti["1d"] >= -5 && ti["4h"] >= -5 ? (
            <Grid.Col lg={12}>
              <Center>
                <Alert color="green">
                  <Center>
                    <Group>
                      {[
                        { interval: "1w", value: ti["1w"] },
                        { interval: "1d", value: ti["1d"] },
                        { interval: "4h", value: ti["4h"] },
                      ].map(({ interval, value }) => {
                        return (
                          <Text key={interval}>
                            <b>{interval}:</b>{" "}
                            <Text
                              color={value >= 0 ? "teal" : "red"}
                              span={true}
                            >
                              <b>{value}</b>
                            </Text>
                          </Text>
                        );
                      })}
                    </Group>
                  </Center>
                  <Text color="green" component="span">
                    <b>FYI!</b>
                  </Text>{" "}
                  <b>Trend Index is favorable for short-term long positions!</b>
                </Alert>
              </Center>
            </Grid.Col>
          ) : (
            <Grid.Col lg={12}>
              <Center>
                <Alert color="red">
                  <Center>
                    <Group>
                      {[
                        { interval: "1w", value: ti["1w"] },
                        { interval: "1d", value: ti["1d"] },
                        { interval: "4h", value: ti["4h"] },
                      ].map(({ interval, value }) => {
                        return (
                          <Text key={interval}>
                            <b>{interval}:</b>{" "}
                            <Text
                              color={value >= 0 ? "teal" : "red"}
                              span={true}
                            >
                              <b>{value}</b>
                            </Text>
                          </Text>
                        );
                      })}
                    </Group>
                  </Center>
                  <Text color="red" component="span">
                    <b>Warning!</b>
                  </Text>{" "}
                  <b>
                    Trend Index is unfavorable for short-term long positions!
                  </b>
                </Alert>
              </Center>
            </Grid.Col>
          )}
        </Grid>
      </>
    );
};

export default function Orders() {
  const dispatch = useDispatch();

  const [sourceFilter, setSourceFilter] = useState("all");
  const [platformFilter, setPlatformFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");
  const [tickerFilter, setTickerFilter] = useState("");
  const [sortBy, setSortBy] = useState("createdDes");
  const [showNewOrderForm, setShowNewOrderForm] = useState(true);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [visibleOrders, setVisibleOrders] = useState([]);
  const [creators, setCreators] = useState([]);

  const loaderRef = useRef(null);
  const observerRef = useRef(null);
  const [hasMore, setHasMore] = useState(false);

  const orders = useSelector((state) => state.orders);

  useEffect(() => {
    if (!orders?.real?.length) return;
    setCreators([
      ...new Set(
        orders.real
          .filter((order) => order.source === "bot")
          .map((order) => order.creatorId)
      ),
    ]);
  }, [orders]);

  useEffect(() => {
    dispatch({ type: WATCH_PRICES });
    dispatch(watchOrders());
  }, []);

  useEffect(() => {
    if (!orders?.real?.length) {
      return setFilteredOrders([]);
    }

    let modified = [...orders?.real];

    if (!!tickerFilter) {
      modified = modified.filter((order) =>
        new RegExp(tickerFilter, "i").test(order.symbol)
      );
    }

    if (sourceFilter !== "all") {
      if (["user", "bot"].includes(sourceFilter))
        modified = modified.filter((order) => order.source === sourceFilter);
      else
        modified = modified.filter((order) => order.creatorId === sourceFilter);
    }

    if (platformFilter !== "all") {
      modified = modified.filter((order) => order.platform === platformFilter);
    }

    if (statusFilter !== "all") {
      modified = modified.filter((order) => order.status === statusFilter);
    }

    if (sortBy === "createdDes") {
      modified.sort((a, b) => new Date(b.created) - new Date(a.created));
    } else if (sortBy === "createdAsc") {
      modified.sort((a, b) => new Date(a.created) - new Date(b.created));
    } else if (sortBy === "profitPercentDes") {
      modified.sort((a, b) => {
        return (
          (b.summary?.profitPercent || 0) - (a.summary?.profitPercent || 0)
        );
      });
    } else if (sortBy === "profitPercentAsc") {
      modified.sort(
        (a, b) =>
          (a.summary?.profitPercent || 0) - (b.summary?.profitPercent || 0)
      );
    } else if (sortBy === "profitDes") {
      modified.sort(
        (a, b) => (b.summary?.profit || 0) - (a.summary?.profit || 0)
      );
    } else if (sortBy === "profitAsc") {
      modified.sort(
        (a, b) => (a.summary?.profit || 0) - (b.summary?.profit || 0)
      );
    }

    setFilteredOrders(modified);
    setVisibleOrders(modified.slice(0, 5));
    if (modified.length > 5) setHasMore(true);
  }, [
    orders,
    sourceFilter,
    platformFilter,
    statusFilter,
    tickerFilter,
    sortBy,
  ]);

  useEffect(() => {
    if (!hasMore) return;

    // delete previous observer
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          console.log("...loading more orders...");
          loadMoreItems();
        }
      },
      {
        root: null,
        rootMargin: "0px 0px 250px 0px",
        threshold: 0,
      }
    );

    if (loaderRef.current) {
      observerRef.current.observe(loaderRef.current);
    }

    // Очистка
    return () => {
      if (observerRef.current && loaderRef.current) {
        observerRef.current.unobserve(loaderRef.current);
      }
    };
  }, [visibleOrders, hasMore]);

  const { ordersLength, profitPercent, profit } = useMemo(() => {
    // todo: now you are taking profit from summary, so only for executed parts,
    //  maybe take also for left ones, but you need curent price for each order in this case
    return {
      ordersLength: filteredOrders.length,
      profitPercent: Number(
        filteredOrders
          .reduce((sum, order) => sum + (order.summary?.profitPercent || 0), 0)
          .toFixed(1)
      ),
      profit: Number(
        filteredOrders
          .reduce((sum, order) => sum + (order.summary?.profit || 0), 0)
          .toFixed(1)
      ),
    };
  }, [filteredOrders]);

  const loadMoreItems = () => {
    setVisibleOrders((prev) => filteredOrders.slice(0, prev.length + 5));
    if (visibleOrders?.length + 5 >= filteredOrders.length) {
      setHasMore(false);
    }
  };

  return (
    <Grid>
      <Grid.Col lg={12}>
        <Center>
          <Group>
            <Select
              w="8rem"
              label="Source"
              placeholder="Source"
              value={sourceFilter}
              onChange={(value) => setSourceFilter(value)}
              maxDropdownHeight="500px"
              data={[
                { value: "all", label: "All" },
                { value: "user", label: "User" },
                { value: "bot", label: "Bot" },
                ...creators.map((creator) => ({
                  value: creator,
                  label: creator,
                })),
              ]}
            />
            <Select
              w="8rem"
              label="Platform"
              placeholder="Platform"
              value={platformFilter}
              onChange={(value) => setPlatformFilter(value)}
              data={[
                { value: "all", label: "All" },
                { value: "binance", label: "Binance" },
                { value: "bybit", label: "Bybit" },
                { value: "mexc", label: "Mexc" },
              ]}
            />
            <Select
              w="8rem"
              label="Status"
              placeholder="Status"
              value={statusFilter}
              onChange={(value) => setStatusFilter(value)}
              data={[
                { value: "all", label: "All" },
                { value: "buy", label: "Buy" },
                { value: "sell", label: "Sell" },
                { value: "stopped", label: "Stopped" },
                { value: "executed", label: "Executed" },
              ]}
            />
            <TextInput
              w="8rem"
              label="Ticker"
              placeholder="Ticker"
              value={tickerFilter}
              onChange={(e) => setTickerFilter(e.target.value)}
            />
            <Select
              w="8rem"
              label="Sort By"
              placeholder="Sort by"
              value={sortBy}
              onChange={(value) => setSortBy(value)}
              maxDropdownHeight="500px"
              data={[
                { value: "createdDes", label: "Created ↓" },
                { value: "createdAsc", label: "Created ↑" },
                { value: "profitPercentDes", label: "Profit % ↓" },
                { value: "profitPercentAsc", label: "Profit % ↑" },
                { value: "profitDes", label: "Profit $ ↓" },
                { value: "profitAsc", label: "Profit $ ↑" },
              ]}
            />
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Divider
                size="md"
                orientation="vertical"
                style={{
                  height: "30px",
                  marginTop: "1.5rem",
                }}
              />
            </div>
            <BasicStats
              ordersLength={ordersLength}
              profitPercent={profitPercent}
              profit={profit}
            />
          </Group>
        </Center>
      </Grid.Col>
      <Grid.Col lg={12}>
        <Center>
          <Group>
            <TrendIndexWarning />
          </Group>
        </Center>
      </Grid.Col>
      {/*<Grid.Col style={{ display: "flex", justifyContent: "center" }}>*/}
      {/*  <Text size="sm">*/}
      {/*    <b>{filteredOrders?.length} order(s)</b>*/}
      {/*  </Text>*/}
      {/*</Grid.Col>*/}
      {showNewOrderForm ? (
        <Grid.Col md={6} sm={12}>
          <NewOrder hideForm={() => setShowNewOrderForm(false)} />
        </Grid.Col>
      ) : (
        <Grid.Col span={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            leftIcon={<IconPlus />}
            onClick={() => setShowNewOrderForm(true)}
          >
            Create new order
          </Button>
        </Grid.Col>
      )}
      {visibleOrders.map((order) => {
        if (order.status === "closed" || order.status === 1000) {
          return null;
        }

        return (
          <Grid.Col key={order._id} md={6} sm={12}>
            <Order order={order} isFake={order.isFake} />
          </Grid.Col>
        );
      })}
      <Grid.Col
        ref={loaderRef}
        span={12}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {hasMore ? (
          <Text>
            <b>... loading ...</b>
          </Text>
        ) : (
          <BasicStats
            ordersLength={ordersLength}
            profitPercent={profitPercent}
            profit={profit}
          />
        )}
      </Grid.Col>
    </Grid>
  );
}
