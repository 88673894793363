import { IconPlus, IconX } from "@tabler/icons-react";
import {
  Button,
  CopyButton,
  Grid,
  Input,
  NumberInput,
  SegmentedControl,
  Switch,
  Group,
} from "@mantine/core";
import { useSelector } from "react-redux";

import AmountPercentPicker from "../AmountPercentPicker";

function SellOnPercent({ symbol, strategy, updateStrategy, removeStrategy }) {
  let currentPrice = useSelector((state) => {
    return state.currentPrice[`${symbol.replace("USDT", "")}USDT`];
  });
  currentPrice = currentPrice || 0;

  return (
    <Grid>
      <Grid.Col
        xl={3}
        lg={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          onClick={() => removeStrategy(strategy)}
        >
          Sell On (%)
        </Button>
      </Grid.Col>
      <Grid.Col xl={9} lg={12}>
        <Grid>
          <Grid.Col xl={12}>
            <Grid>
              <Grid.Col xl={8} lg={12}>
                <Group>
                  <NumberInput
                    w="7rem"
                    // label="Down %"
                    placeholder="Down %"
                    precision={1}
                    step={1}
                    value={strategy.params.down}
                    onChange={(value) =>
                      updateStrategy({
                        ...strategy,
                        params: { ...strategy.params, down: value },
                      })
                    }
                  />
                  <NumberInput
                    w="7rem"
                    // label="Up %"
                    placeholder="Up %"
                    precision={1}
                    step={1}
                    value={strategy.params.up}
                    onChange={(value) =>
                      updateStrategy({
                        ...strategy,
                        params: { ...strategy.params, up: value },
                      })
                    }
                  />
                  <CopyButton value={currentPrice}>
                    {({ copied, copy }) => (
                      <Button variant="white" color="dark" onClick={copy}>
                        {currentPrice}$
                      </Button>
                    )}
                  </CopyButton>
                </Group>
              </Grid.Col>
              <Grid.Col
                xl={2}
                lg={12}
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <AmountPercentPicker
                  value={strategy.params.sellAmount}
                  onChange={(value) => {
                    updateStrategy({
                      ...strategy,
                      params: { ...strategy.params, sellAmount: value },
                    });
                  }}
                />
              </Grid.Col>
              <Grid.Col
                xl={2}
                lg={12}
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "flex-end",
                  paddingBottom: "1rem",
                }}
              >
                <Switch
                  label={<b>AND</b>}
                  checked={strategy.params.and}
                  onClick={(e) =>
                    updateStrategy({
                      ...strategy,
                      params: { ...strategy.params, and: e.target.checked },
                    })
                  }
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col xl={12}>
            <Group>
              <SegmentedControl
                value={strategy.params.base}
                onChange={(value) =>
                  updateStrategy({
                    ...strategy,
                    params: { ...strategy.params, base: value },
                  })
                }
                data={[
                  { value: "buy", label: "buy" },
                  { value: "ceil", label: "ceil" },
                  { value: "bottom", label: "bottom" },
                  { value: "custom", label: "custom" },
                ]}
              />
              <Input
                w="7rem"
                label="Custom ($)"
                placeholder="Custom ($)"
                value={strategy.params.customPrice}
                disabled={strategy.params.base !== "custom"}
                onChange={(e) =>
                  updateStrategy({
                    ...strategy,
                    params: {
                      ...strategy.params,
                      customPrice: e.target.value,
                    },
                  })
                }
              />
            </Group>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

SellOnPercent.type = "sell";
SellOnPercent.color = "teal";
SellOnPercent.model = {
  name: "sell_on_percent",
  title: "Sell on percent",
  params: {
    down: "",
    up: "",
    base: "buy",
    customPrice: "",
    sellAmount: 100,
  },
};
SellOnPercent.paramsTitle = (strategy) => {
  let { up, down, base } = strategy.params;

  if (up !== "" && Number(up) >= 0) up = `+${up}`;
  if (down !== "" && Number(down) >= 0) down = `+${down}`;

  if (down !== "" && up !== "") {
    return `${down}% - ${up}% | ${strategy.params.sellAmount || 100}%`;
  } else if (strategy.params.down !== "") {
    return `<= ${down}%, ${base || "buy"} | ${
      strategy.params.sellAmount || 100
    }%`;
  } else if (up !== "") {
    return `>= ${up}%, ${base || "buy"} | ${
      strategy.params.sellAmount || 100
    }%`;
  } else {
    return `-`;
  }
};

SellOnPercent.Button = (props) => {
  return (
    <Button variant="default" color="teal" leftIcon={<IconPlus />} {...props}>
      Sell On (%)
    </Button>
  );
};

export default SellOnPercent;
