import { IconPlus, IconX } from "@tabler/icons-react";
import { Button, Grid, SegmentedControl } from "@mantine/core";

function InstantBuy({ strategy, updateStrategy, removeStrategy }) {
  return (
    <Grid>
      <Grid.Col
        xl={3}
        lg={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          onClick={() => removeStrategy(strategy)}
        >
          Instant buy
        </Button>
      </Grid.Col>
    </Grid>
  );
}

InstantBuy.type = "buy";
InstantBuy.color = "pink";
InstantBuy.model = {
  name: "instant_buy",
  title: "Instant buy ",
  // algorithm: "instant_buy",
  params: {},
};
InstantBuy.paramsTitle = () => {
  return "Instant buy";
};
InstantBuy.Button = function (props) {
  return (
    <Button variant="default" color="violet" leftIcon={<IconPlus />} {...props}>
      Instant Buy
    </Button>
  );
};

export default InstantBuy;
