import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Provider as ReduxProvider, useDispatch } from "react-redux";
import axios from "axios";

import { store } from "./store";
import AppShell from "./common/components/AppShell";
import ProtectedRoute from "./common/components/ProtectedRoute";
import { logout } from "./common/data/auth/redux";
import Mongo from "./common/sevices/mongo";

import Login from "./Login";
import Trade from "./Trade";
import Settings from "./Settings";
import AlertView from "./AlertView";
import Mtproto from "./Mtproto";
import TI from "./TI";
import Starred from "./Starred";
import { watchStarred } from "./common/data/starred/redux";

Mongo.cluster = Mongo.clusters.dedicated;
Mongo.tokensDb = Mongo.dbs.main;

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response.status === 403 &&
      error.request.responseURL.includes("api")
    ) {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <AppShell>
        <Login />
      </AppShell>
    ),
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <AppShell>
          <Trade />
        </AppShell>
      </ProtectedRoute>
    ),
  },
  {
    path: "/mtproto",
    element: (
      <ProtectedRoute>
        <AppShell>
          <Mtproto />
        </AppShell>
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/daily",
  //   element: (
  //     <ProtectedRoute>
  //       <AppShell>
  //         <Daily />
  //       </AppShell>
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/trade",
    element: (
      <ProtectedRoute>
        <AppShell>
          <Trade />
        </AppShell>
      </ProtectedRoute>
    ),
  },
  {
    path: "/starred",
    element: (
      <ProtectedRoute>
        <AppShell>
          <Starred />
        </AppShell>
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/account",
  //   element: (
  //     <ProtectedRoute>
  //       <AppShell>
  //         <Account />
  //       </AppShell>
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <AppShell>
          <Settings />
        </AppShell>
      </ProtectedRoute>
    ),
  },
  {
    path: "/alert-view",
    element: (
      <ProtectedRoute>
        <AppShell>
          <AlertView />
        </AppShell>
      </ProtectedRoute>
    ),
  },
  {
    path: "/ti",
    element: (
      <ProtectedRoute>
        <AppShell>
          <TI />
        </AppShell>
      </ProtectedRoute>
    ),
  },
]);

const App = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(watchStarred());
  }, []);

  return children;
};

function Index() {
  return (
    <ReduxProvider store={store}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          globalStyles: (theme) => ({
            "html, body, #root": {
              height: "100%",
              // fontSize: "15px",
            },
          }),
          // primaryColor: "indigo",
        }}
      >
        <App>
          <RouterProvider router={router}></RouterProvider>
        </App>
      </MantineProvider>
    </ReduxProvider>
  );
}

export default Index;
