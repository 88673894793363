import { useState, useMemo } from "react";
import { Grid, Group, Title, Center, SegmentedControl } from "@mantine/core";
import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";

export const Tokens = ({
  volumeThreshold,
  tokens,
  interval,
  noCharts,
  sortBy,
  sortDir,
  setSortBy,
  setSortDir,
  AlertTable,
}) => {
  if (!tokens?.length) return null;

  let title = `Volume > ${volumeThreshold}m $ (${tokens.length})`;

  return (
    <Grid>
      <Grid.Col span={12}>
        <Center>
          <Title order={5} underline={true}>
            {title}
          </Title>
        </Center>
      </Grid.Col>
      <Grid.Col lg={noCharts ? 12 : 3}>
        <AlertTable
          tokens={tokens}
          sortBy={sortBy}
          sortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
      </Grid.Col>
    </Grid>
  );
};

export const TokensWithChart = ({
  volumeThreshold,
  tokens,
  interval,
  noCharts,
  sortBy,
  sortDir,
  setSortBy,
  setSortDir,
  AlertTable,
}) => {
  if (!tokens?.length) return null;

  let title = `Volume > ${volumeThreshold}m $ (${tokens.length})`;

  return (
    <Grid>
      <Grid.Col span={12}>
        <Center>
          <Title order={5} underline={true}>
            {title}
          </Title>
        </Center>
      </Grid.Col>
      <Grid.Col lg={4} md={4}>
        <AlertTable
          tokens={tokens}
          sortBy={sortBy}
          sortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
      </Grid.Col>
      <Grid.Col lg={8} md={8}>
        <Grid>
          {tokens.map((token) => {
            let { symbol, platform } = token;
            return (
              <Grid.Col key={symbol} lg={6} md={6}>
                <Grid>
                  <Grid.Col span={12}>
                    <AlertTable
                      token={token}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      setSortBy={setSortBy}
                      setSortDir={setSortDir}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TradingViewAdvancedChartWidget
                      symbol={symbol}
                      platform={platform.toUpperCase()}
                      width="100%"
                      interval={"1d"}
                      height={"400px"}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            );
          })}
        </Grid>
      </Grid.Col>
    </Grid>
  );
};

export default function MultipleTokensAlert({
  title,
  alert,
  AlertTable,
  sortFunc,
  defaultSortBy,
  defaultSortDir,
  tableSpan,
}) {
  const [withChartGroup, setWithChartGroup] = useState("20");

  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [sortDir, setSortDir] = useState(defaultSortDir || "des");

  const tokensMap = useMemo(() => {
    if (!alert?.alert || !Object.values(alert.alert).length) return [];
    const objects = Object.values(alert.alert);
    let map = {
      20: [],
      10: [],
      5: [],
      1: [],
    };
    for (let obj of objects) {
      if (obj.quoteVolume > 20000000) map["20"].push(obj);
      else if (obj.quoteVolume > 10000000) map["10"].push(obj);
      else if (obj.quoteVolume > 5000000) map["5"].push(obj);
      else if (obj.quoteVolume > 1000000) map["1"].push(obj);
    }
    for (let m in map) {
      map[m].sort(sortFunc({ sortDir, sortBy }));
    }
    // if (sortDir === "des") {
    //   for (let m in map) {
    //     map[m].sort((a, b) => b[sortBy] - a[sortBy]);
    //   }
    // } else {
    //   for (let m in map) {
    //     map[m].sort((a, b) => a[sortBy] - b[sortBy]);
    //   }
    // }
    return map;
  }, [alert, sortBy, sortDir]);

  if (!alert?.alert) return null;

  let mainClm = `${title} (${Object.keys(alert.alert).length})`;
  let intervalClm = alert.interval ? `📊 ${alert.interval}` : null;
  let dayMonth = new Date(alert.openTime).toLocaleString("en-GB", {
    day: "numeric",
    month: "short",
  });
  let hrStart = new Date(alert.openTime).toLocaleString(undefined, {
    hour: "numeric",
    minute: undefined,
  });
  let hrEnd = new Date(new Date(alert.closeTime).getTime() + 1).toLocaleString(
    undefined,
    {
      hour: "numeric",
      minute: undefined,
    }
  );
  let dateClm = `⏱️ ${dayMonth} ‧ ${hrStart} - ${hrEnd}`;

  return (
    <Grid>
      <Grid.Col span={12}>
        <Center>
          <Group>
            <Title order={5}>{mainClm}</Title>
            <Title order={5}>{dateClm}</Title>
            {intervalClm && <Title order={5}>{intervalClm}</Title>}
          </Group>
        </Center>
      </Grid.Col>
      <Grid.Col span={12}>
        <Grid>
          {Object.keys(tokensMap)
            .sort((a, b) => b - a)
            .map((key) => {
              return (
                <Grid.Col lg={4} key={key}>
                  <Tokens
                    interval={alert.interval}
                    tokens={tokensMap[key]}
                    volumeThreshold={key}
                    noCharts={true}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    setSortBy={setSortBy}
                    setSortDir={setSortDir}
                    AlertTable={AlertTable}
                  />
                </Grid.Col>
              );
            })}
        </Grid>
      </Grid.Col>
      <Grid.Col lg={12}>
        <Center>
          <SegmentedControl
            onChange={setWithChartGroup}
            data={[
              { label: "> 20m", value: "20" },
              { label: "> 10m", value: "10" },
              { label: "> 5m", value: "5" },
              { label: "> 1m", value: "1" },
            ]}
          />
        </Center>
      </Grid.Col>
      <Grid.Col lg={12}>
        <TokensWithChart
          interval={alert.interval}
          tokens={tokensMap[withChartGroup]}
          volumeThreshold={withChartGroup}
          sortBy={sortBy}
          sortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
          AlertTable={AlertTable}
        />
      </Grid.Col>
    </Grid>
  );
}
