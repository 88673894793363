import { useDispatch, useSelector } from "react-redux";
import { toFixed, toFixedMeaningful, toK } from "../../common/helpers";
import * as helpers from "../../common/helpers";
import { useEffect } from "react";
import { profitsSlice } from "../../common/data/profits/redux";
import { Grid, Text, Tooltip } from "@mantine/core";

import Symbol from "./Symbol";
import Status from "./Status";
import Summary from "./Summary";

/*
  BuyToSellFields is used when start action is BUY and:
  1. order has status SELL
  2. order is stopped on SELL status
  3. order is executed
  */

function getSellsInCurrentCycle({ order }) {
  let sellsInCurrentCycle = [];
  for (let i = order.ops.length - 1; i >= 0; i--) {
    if (order.ops[i].action === "buy") break;
    else sellsInCurrentCycle.push(order.ops[i]);
  }
  sellsInCurrentCycle.reverse();
  return sellsInCurrentCycle;
}

function getSoldAmountInCurrentCycle({ order }) {
  let sells = getSellsInCurrentCycle({ order });
  if (!sells.length) return 0;
  return sells.reduce((sum, sell) => sum + sell.amount, 0);
}

function getFees({ order }) {
  let buySellsInCurrentCycle = [];
  for (let i = order.ops.length - 1; i >= 0; i--) {
    if (order.ops[i].action === "buy") {
      buySellsInCurrentCycle.push(order.ops[i]);
      break;
    } else buySellsInCurrentCycle.push(order.ops[i]);
  }
  return buySellsInCurrentCycle.reduce(
    (sum, actionResult) => sum + actionResult.feeUsd,
    0
  );
}

function ActiveOrStopped({ order }) {
  const dispatch = useDispatch();

  const currentPrice =
    useSelector((state) => state.currentPrice[order.symbol]) || 0;

  // values
  const onlySymbol = order.symbol.replace("USDT", "");
  const buyPrice = toFixedMeaningful(order.buy?.price, 3);
  const buyAmount = order.buy?.amount;
  const partialSoldAmount = getSoldAmountInCurrentCycle({ order });
  const leftAmount = toFixedMeaningful(buyAmount - partialSoldAmount, 3);
  const invested = toFixed(buyAmount * buyPrice, 1);
  const investedLeft = toFixed(leftAmount * buyPrice, 2);
  const priceChange = currentPrice
    ? helpers.diffToPercent(currentPrice / order.buy.price, 1)
    : 0;
  const leverage = order?.futuresStrategy?.leverage;

  let gain = 0;
  if (currentPrice) {
    if (!order.market || order.market === "spot" || order.position === "long") {
      gain = toFixed(leftAmount * currentPrice - leftAmount * buyPrice, 1);
    } else if (order.position === "short") {
      gain = toFixed(leftAmount * buyPrice - leftAmount * currentPrice, 1);
    }
  }
  const feeUsd = order.buy.feeUsd + leftAmount * currentPrice * 0.00075; // ! approximate value
  const profit = toFixed(gain - feeUsd, 1);

  // titles
  // 1st column
  const buyPriceTitle = `Buy price: ${buyPrice}`;
  const currentPriceTitle = `Curr. price: ${currentPrice}`;
  // 2nd column
  const leftPercentage = toFixed((leftAmount / buyAmount) * 100);
  let sellAmountTitle = `Sell: ${toK(buyAmount)} ${onlySymbol}`;
  if (leftPercentage < 100) {
    sellAmountTitle += ` (${leftPercentage}% left)`;
  }
  const priceChangeTitle = `${
    priceChange > 0 ? `+${priceChange}` : priceChange
  }%`;
  const profitTitle = `${profit}$`;
  // 3rd column
  let investedTitle =
    order.market === "futures"
      ? `Total: ${toK(invested / leverage, 1)}$ * ${leverage} = ${toK(
          invested,
          1
        )}$`
      : `Invested: ${toK(invested, 1)}$`;

  // tooltips
  const sellAmountTooltip = `Left: ${leftAmount} ${onlySymbol}`;
  const investedTooltip = `Left: ${investedLeft}$`;
  const profitTooltip = `${investedLeft}$ → ${toFixed(
    leftAmount * currentPrice
  )}$`;

  useEffect(
    function updateSymbolInDocumentTitle() {
      // console.log(order.symbol, profit);
      dispatch(
        profitsSlice.actions.update({
          set: true,
          key: order._id,
          value: { symbol: order.symbol, profit },
        })
      );
    },
    [profit]
  );

  useEffect(function removeSymbolFromDocumentTitle() {
    return () => {
      dispatch(profitsSlice.actions.update({ unset: true, key: order._id }));
    };
  }, []);

  return (
    <Grid>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Symbol order={order} />
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Status order={order} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Text align="center" fw={700} size="sm">
              {buyPriceTitle}
            </Text>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Text align="center" fw={700} size="sm">
              {currentPriceTitle}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Tooltip label={sellAmountTooltip}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {sellAmountTitle}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Tooltip label={profitTooltip}>
              <div style={{ textAlign: "center", cursor: "pointer" }}>
                <Text
                  align="center"
                  fw={700}
                  size="sm"
                  color={
                    ((order.market === "spot" || !order.market) &&
                      priceChange >= 0) ||
                    (order.position === "long" && priceChange >= 0) ||
                    (order.position === "short" && priceChange <= 0)
                      ? "teal"
                      : "red"
                  }
                  span={true}
                >
                  {priceChangeTitle}
                </Text>
                <Text
                  align="center"
                  fw={700}
                  size="sm"
                  span={true}
                  color="gray"
                >
                  {" "}
                  /{" "}
                </Text>
                <Text
                  align="center"
                  fw={700}
                  size="sm"
                  color={profit >= 0 ? "teal" : "red"}
                  span={true}
                >
                  {profitTitle}
                </Text>
              </div>
            </Tooltip>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Tooltip label={investedTooltip}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {investedTitle}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Summary
              order={order}
              isTemp={true}
              leftAmount={leftAmount}
              buyPrice={buyPrice}
              currentPrice={currentPrice}
              unfixedProfit={profit}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

function Executed({ order }) {
  const dispatch = useDispatch();

  const currentPrice =
    useSelector((state) => state.currentPrice[order.symbol]) || 0;

  // values
  const sells = getSellsInCurrentCycle({ order });
  const onlySymbol = order.symbol.replace("USDT", "");
  const buyPrice = toFixedMeaningful(order.buy?.price, 3);
  const sellPrice = toFixedMeaningful(order?.sell?.price, 3);
  const buyAmount = order.buy?.amount;
  const buyAmountUsd = toFixed(order.buy?.amountUsd, 1); // ! we skip buy fee here
  const totalSellAmount = sells.reduce((sum, sell) => sum + sell.amount, 0);
  const totalSellAmountUsd = sells.reduce(
    (sum, sell) => sum + (sell.amountUsd - sell.feeUsd),
    0
  );
  // const priceChange = currentPrice
  //   ? helpers.diffToPercent(order.sell.price / order.buy.price, 1)
  //   : 0;
  const priceChange = order.stat.profitPercent;
  const leverage = order?.futuresStrategy?.leverage;
  const profit = toFixed(order.stat?.profit, 1);

  // titles
  // 1st column
  const buyPriceTitle = `Buy price: ${buyPrice}`;
  const sellPriceTitle = `Sell price: ${sellPrice}`;
  // 2nd column
  const sellAmountTitle = `Sold: ${toK(buyAmount)} ${onlySymbol}`;
  const priceChangeTitle = `${
    priceChange > 0 ? `+${priceChange}` : priceChange
  }%`;
  const profitTitle = `${profit}$`;
  // 3rd column
  const investedTitle =
    order.market === "futures"
      ? `Total: ${toK(buyAmountUsd / leverage, 1)}$ * ${leverage} = ${toK(
          buyAmountUsd,
          1
        )}$`
      : `Invested: ${toK(buyAmountUsd, 1)}$`;

  // tooltips
  const sellAmountTooltip = `Sold: ${totalSellAmount} ${onlySymbol}`;
  const investedTooltip = `Invested: ${toFixed(buyAmountUsd, 1)}$`;
  const profitTooltip = `${toFixed(order.buy?.amountUsd, 1)}$ → ${toFixed(
    totalSellAmountUsd,
    1
  )}$`;

  useEffect(
    function updateSymbolInDocumentTitle() {
      // console.log(order.symbol, profit);
      dispatch(
        profitsSlice.actions.update({
          set: true,
          key: order._id,
          value: { symbol: order.symbol, profit },
        })
      );
    },
    [profit]
  );

  useEffect(function removeSymbolFromDocumentTitle() {
    return () => {
      dispatch(profitsSlice.actions.update({ unset: true, key: order._id }));
    };
  }, []);

  return (
    <Grid>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Symbol order={order} />
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Status order={order} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Text align="center" fw={700} size="sm">
              {buyPriceTitle}
            </Text>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Text align="center" fw={700} size="sm">
              {sellPriceTitle}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Tooltip label={sellAmountTooltip}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {sellAmountTitle}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Tooltip label={profitTooltip}>
              <div style={{ textAlign: "center", cursor: "pointer" }}>
                <Text
                  align="center"
                  fw={700}
                  size="sm"
                  color={
                    ((order.market === "spot" || !order.market) &&
                      priceChange >= 0) ||
                    (order.position === "long" && priceChange >= 0) ||
                    (order.position === "short" && priceChange <= 0)
                      ? "teal"
                      : "red"
                  }
                  span={true}
                >
                  {priceChangeTitle}
                </Text>
                <Text
                  align="center"
                  fw={700}
                  size="sm"
                  span={true}
                  color="gray"
                >
                  {" "}
                  /{" "}
                </Text>
                <Text
                  align="center"
                  fw={700}
                  size="sm"
                  color={profit >= 0 ? "teal" : "red"}
                  span={true}
                >
                  {profitTitle}
                </Text>
              </div>
            </Tooltip>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Tooltip label={investedTooltip}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {investedTitle}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Summary order={order} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

export default function BuyToSellFields({
  order,
  isActive,
  isStopped,
  isExecuted,
}) {
  if (isExecuted) {
    return <Executed order={order} />;
  } else if (isActive || isStopped) {
    return <ActiveOrStopped order={order} />;
  }
}
