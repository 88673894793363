import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, Text, Button, Grid, Group } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlertCircle } from "@tabler/icons-react";

import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { UPDATE_ORDER, updateOrder } from "../../common/data/orders/redux";
import {
  subscribeToPrice,
  unsubscribeFromPrice,
} from "../../common/data/currentPrice/redux";

import { StrategyPickerModal, StrategyPickerBoard } from "../StrategyPicker";
import AmountPercentPicker from "../StrategyPicker/AmountPercentPicker";
import {
  AmountStrategyBoardButton,
  AmountStrategyPickerModal,
} from "../AmountStrategyPicker";
import { FuturesStrategyBoardButton } from "../FuturesStrategyPicker";
import StarButton from "../StarButton";
import * as Buttons from "./Buttons";

import BuyFields from "./BuyFields";
import BuyToSellFields from "./BuyToSellFields";
import SingleSellFields from "./SingleSellFields";

export default function Order({ order, isFake, showStarButton = true }) {
  const dispatch = useDispatch();

  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  const [isSubscribedToPrice, setIsSubscribedToPrice] = useState(false);

  const [buyStrategies, setBuyStrategies] = useState(
    JSON.parse(JSON.stringify(order.buyStrategies || []))
  );
  const [sellStrategies, setSellStrategies] = useState(
    JSON.parse(JSON.stringify(order.sellStrategies || []))
  );
  const [repeatStrategies, setRepeatStrategies] = useState(
    JSON.parse(JSON.stringify(order.repeatStrategies || []))
  );
  const [showStrategyPicker, setShowStrategyPicker] = useState(false);

  const [amountStrategy, setAmountStrategy] = useState(
    JSON.parse(JSON.stringify(order.amountStrategy || {}))
  );
  const [showAmountStrategyPicker, setShowAmountStrategyPicker] =
    useState(false);

  const [futuresStrategy, setFuturesStrategy] = useState(
    order.futuresStrategy || {}
  );

  const updateOrderProcess = useSelector(
    (state) => state.processes[`${UPDATE_ORDER}-${order._id}`]
  );

  const [sellAmount, setSellAmount] = useState(100);

  useEffect(() => {
    if (!["buy", "sell", "stopped"].includes(order.status)) {
      return;
    }

    dispatch(
      subscribeToPrice({
        symbol: order.symbol,
        platform: order.platform,
        id: order._id,
      })
    );

    return () => {
      setIsSubscribedToPrice(false);
      dispatch(
        unsubscribeFromPrice({
          symbol: order.symbol,
          platform: order.platform,
          id: order._id,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (order.status === "executed" && isSubscribedToPrice) {
      dispatch(
        unsubscribeFromPrice({
          symbol: order.symbol,
          platform: order.platform,
          id: order._id,
        })
      );
    }
  }, [order.status, isSubscribedToPrice]);

  // const { sendMessage, lastMessage, readyState } = useWebSocket(
  //   `wss://stream.binance.com:9443/ws/${order.symbol.toLowerCase()}@kline_1s`
  // );
  // const data = lastMessage && JSON.parse(lastMessage.data);
  // const klinePrice = parseFloat(data?.k?.c) || 0;
  // const klinePrice = 0;

  const openAmountStrategyPickerModal = () => {
    setAmountStrategy(JSON.parse(JSON.stringify(order.amountStrategy || {})));
    setShowAmountStrategyPicker(true);
  };

  const openStrategyPickerModal = () => {
    setBuyStrategies(JSON.parse(JSON.stringify(order.buyStrategies || [])));
    setSellStrategies(JSON.parse(JSON.stringify(order.sellStrategies || [])));
    setRepeatStrategies(
      JSON.parse(JSON.stringify(order.repeatStrategies || []))
    );
    setShowStrategyPicker(true);
  };

  const update = () => {
    dispatch(
      updateOrder({
        id: order._id,
        updateOb: {
          buyStrategies: buyStrategies,
          sellStrategies: sellStrategies,
          repeatStrategies: repeatStrategies,
          amountStrategy: amountStrategy,
        },
        onSuccess: () => {
          setShowStrategyPicker(false);
          setShowAmountStrategyPicker(false);
        },
      })
    );
  };

  const closeError = () => {
    dispatch(
      updateOrder({
        id: order._id,
        updateOb: {
          errors: [
            ...order.errors.slice(0, order.errors.length - 1),
            { ...order.errors[order.length - 1], isClosed: true },
          ],
        },
        onSuccess: () => setShowStrategyPicker(false),
      })
    );
  };

  const isActive = order.status === "buy" || order.status === "sell";
  const isStopped = order.status === "stopped";
  const isExecuted = order.status === "executed";
  const isBuy =
    order.start === "buy" &&
    (order.status === "buy" ||
      (order.status === "stopped" && order.stoppedOn === "buy"));
  const isBuyToSell =
    order.start === "buy" &&
    (order.status === "sell" ||
      (order.status === "stopped" && order.stoppedOn === "sell") ||
      order.status === "executed");
  const isSingleSell =
    order.start === "sell" &&
    (order.status === "sell" ||
      (order.status === "stopped" && order.stoppedOn === "sell") ||
      order.status === "executed");

  const lastError = order.errors?.[order.errors.length - 1];

  const { symbol } = order;

  return (
    <>
      <AmountStrategyPickerModal
        show={showAmountStrategyPicker}
        amountStrategy={amountStrategy}
        setAmountStrategy={setAmountStrategy}
        onClose={() => setShowAmountStrategyPicker(false)}
        onSubmit={update}
        process={updateOrderProcess}
        hideByVolume={isSingleSell}
      ></AmountStrategyPickerModal>
      <StrategyPickerModal
        symbol={symbol}
        show={showStrategyPicker}
        buyStrategies={buyStrategies}
        sellStrategies={sellStrategies}
        repeatStrategies={repeatStrategies}
        setBuyStrategies={setBuyStrategies}
        setSellStrategies={setSellStrategies}
        setRepeatStrategies={setRepeatStrategies}
        onClose={() => setShowStrategyPicker(false)}
        onSubmit={update}
        process={updateOrderProcess}
        disableBuyStrategies={isSingleSell}
        disableRepeatStrategies={isSingleSell}
      />
      <Grid>
        <Grid.Col span={12}>
          <TradingViewAdvancedChartWidget
            symbol={order.symbol}
            platform={order.platform}
            market={order.market}
            width="100%"
            height={isSmallerThanLarge ? "300px" : "300px"}
            interval="1d"
          />
        </Grid.Col>
        {lastError && !lastError?.isClosed && (
          <Grid.Col span={12}>
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              color="red"
              variant="light"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Text fw={700}>
                  Error: {lastError.message} (
                  {new Date(lastError.date).toLocaleString()})
                </Text>
                <Button size="xs" compact color="red" onClick={closeError}>
                  Ok
                </Button>
              </Box>
            </Alert>
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          {isBuy && (
            <BuyFields
              order={order}
              isActive={order.status === "buy"}
              isStopped={order.status === "stopped"}
              isExecuted={order.status === "executed"}
            />
          )}
          {isSingleSell && (
            <SingleSellFields
              order={order}
              isActive={order.status === "sell"}
              isStopped={order.status === "stopped"}
              isExecuted={order.status === "executed"}
            />
          )}
          {isBuyToSell && (
            <BuyToSellFields
              order={order}
              isActive={order.status === "sell"}
              isStopped={order.status === "stopped"}
              isExecuted={order.status === "executed"}
            />
          )}
        </Grid.Col>
        <Grid.Col span={12}>
          <Group
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {order.market === "futures" && (
              <FuturesStrategyBoardButton
                symbol={symbol}
                position={order.position}
                futuresStrategy={futuresStrategy}
                isFake={isFake}
                // disableButtons={true}
              />
            )}
            <AmountStrategyBoardButton
              symbol={symbol}
              amountStrategy={order.amountStrategy}
              openModal={openAmountStrategyPickerModal}
              isFake={order.isFake}
            />
            <StrategyPickerBoard
              symbol={symbol}
              buyStrategies={order.buyStrategies}
              sellStrategies={order.sellStrategies}
              repeatStrategies={order.repeatStrategies}
              isFake={order.isFake}
              openModal={openStrategyPickerModal}
              disableButtons={isExecuted}
            />
            {showStarButton && (
              <StarButton symbol={symbol} platform={order.platform} />
            )}
            {/*<Button variant="white" color={"gray"}>*/}
            {/*  {order.isHiddenGroup ? (*/}
            {/*    <IconEye*/}
            {/*      onClick={() => {*/}
            {/*        dispatch(*/}
            {/*          updateOrder({*/}
            {/*            id: order._id,*/}
            {/*            updateOb: {*/}
            {/*              isHiddenGroup: false,*/}
            {/*            },*/}
            {/*          })*/}
            {/*        );*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  ) : (*/}
            {/*    <IconEyeOff*/}
            {/*      onClick={() => {*/}
            {/*        dispatch(*/}
            {/*          updateOrder({*/}
            {/*            id: order._id,*/}
            {/*            updateOb: {*/}
            {/*              isHiddenGroup: true,*/}
            {/*            },*/}
            {/*          })*/}
            {/*        );*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</Button>*/}
          </Group>
        </Grid.Col>
        {isActive && (
          <>
            {order.status === "buy" && (
              <>
                <Grid.Col span={6}>
                  <Buttons.StopButton order={order} />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Buttons.BuyButton order={order} />
                </Grid.Col>
              </>
            )}
            {order.status === "sell" && order.start === "buy" && (
              <>
                <Grid.Col span={5}>
                  <Buttons.StopButton order={order} />
                </Grid.Col>
                <Grid.Col span={5}>
                  <Buttons.SellButton order={order} sellAmount={sellAmount} />
                </Grid.Col>
                <Grid.Col span={2}>
                  <AmountPercentPicker
                    value={sellAmount}
                    onChange={(value) => setSellAmount(value)}
                    variant="filled"
                  />
                </Grid.Col>
              </>
            )}
            {order.status === "sell" && order.start === "sell" && (
              <>
                <Grid.Col span={6}>
                  <Buttons.StopButton order={order} />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Buttons.SellButton order={order} />
                </Grid.Col>
              </>
            )}
          </>
        )}
        {isStopped && (
          <>
            <Grid.Col span={4}>
              <Buttons.ResumeButton order={order} />
            </Grid.Col>
            <Grid.Col span={4}>
              <Buttons.CloseButton order={order} />
            </Grid.Col>
            <Grid.Col span={4}>
              <Buttons.DeleteButton order={order} />
            </Grid.Col>
          </>
        )}
        {isExecuted && (
          <>
            <Grid.Col span={6}>
              <Buttons.CloseButton order={order} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Buttons.DeleteButton order={order} />
            </Grid.Col>
          </>
        )}
      </Grid>
    </>
  );
}
