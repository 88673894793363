import {
  Box,
  Text,
  Highlight,
  Group,
  ActionIcon,
  Center,
  Modal,
} from "@mantine/core";
import { getSpotTradingPageUrl } from "../common/helpers";

import { TH } from "../common/components/Table";
import { toggleStarred } from "../common/data/starred/redux";
import {
  IconStarFilled,
  IconTimeline,
  IconInfoCircle,
} from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import TradingViewAdvancedChartWidget from "../common/components/TradingViewAdvancedChartWidget";

export const SymbolTH = () => {
  return <TH title="💎" />;
  // return <TH title="sml" />;
};

export const Symbol = ({ symbol, platform, ll }) => {
  let color = "dark.9";
  let llEmojie = "";
  if (ll <= 3) {
    color = "teal.9";
    llEmojie = "❇️";
  } else if (ll <= 30) {
    color = "orange.9";
    llEmojie = "⭐";
  } else if (ll <= 90) {
    color = "indigo.9";
    llEmojie = "🔷"; // "🔥";
  }
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text size="sm" span={true}>
        {llEmojie}
      </Text>
      <Text
        size="sm"
        weight={700}
        align="center"
        color={color}
        span={true}
        ml="0.2rem"
      >
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={`https://www.tradingview.com/chart/?symbol=${platform.toUpperCase()}:${symbol}`}
          target="_blank"
        >
          {symbol.replace("USDT", "")}
          {/*{!!color ? (*/}
          {/*  <Highlight*/}
          {/*    highlight={symbol.replace("USDT", "")}*/}
          {/*    highlightColor={color}*/}
          {/*    color="white"*/}
          {/*  >*/}
          {/*    {symbol.replace("USDT", "")}*/}
          {/*  </Highlight>*/}
          {/*) : (*/}
          {/*  symbol.replace("USDT", "")*/}
          {/*)}*/}
        </a>
      </Text>
    </Box>
  );
};

export const Platform = ({ symbol, platform }) => {
  return (
    <Text
      size="sm"
      weight={700}
      align="center"
      // color={
      //   platform === "mexc"
      //     ? "indigo.9"
      //     : platform === "binance"
      //     ? "orange.8"
      //     : "dark.9"
      // }
    >
      <a
        style={{ textDecoration: "none", color: "inherit" }}
        href={getSpotTradingPageUrl(symbol, platform)}
        target="_blank"
      >
        {platform}
      </a>
    </Text>
  );
};

const ChartModal = ({ show, onClose, symbol, platform }) => {
  if (!show) return null;
  let onlySymbol = symbol.replace("USDT", "");
  return (
    <Modal
      transitionProps={{ duration: 0 }}
      lockScroll={false}
      opened={show}
      onClose={onClose}
      title={
        <b>
          {onlySymbol} ({platform.toLowerCase()})
        </b>
      }
      size="50%"
    >
      <TradingViewAdvancedChartWidget
        symbol={symbol}
        platform={platform}
        interval="1d"
        width="100%"
        height="500px"
      />
    </Modal>
  );
};

export const Buttons = ({ symbol, platform }) => {
  const dispatch = useDispatch();
  const starred = useSelector((state) => state.starred);
  const [showModal, setShowModal] = useState(false);

  let onlySymbol = symbol.replace("USDT", "");

  return (
    <>
      <ChartModal
        show={showModal}
        onClose={() => setShowModal(false)}
        symbol={symbol}
        platform={platform}
      />
      <Center>
        <Group spacing="0.01rem" grow={false} style={{ flexWrap: "nowrap" }}>
          <ActionIcon
            variant="transparent"
            color={starred.tokens?.[onlySymbol] ? "teal.7" : "gray.2"}
            onClick={() => {
              dispatch(
                toggleStarred({
                  symbol,
                  platform,
                  value: !!starred.tokens?.[onlySymbol],
                })
              );
            }}
          >
            <IconStarFilled size="1.2rem" />
          </ActionIcon>
          <ActionIcon variant="transparent" color="gray.3">
            <IconTimeline size="1.2rem" onClick={() => setShowModal(true)} />
          </ActionIcon>
          <ActionIcon
            variant="transparent"
            color="gray.3"
            component="a"
            // href={`https://chatgpt.com/?q=${encodeURIComponent(
            //   `1.Tell me about crypto-token $${onlySymbol} in 2 languages: Russian & English. Use Russian first. 2. Describe in simple words with examples the purpose and use of this project. 3. Tell me about similar existing projects and why this token can be better. Answer point by point adding numbers as in a prompt.`
            // )}&hints=search`}
            href={`https://chatgpt.com/?q=${encodeURIComponent(
              `Tell me about the crypto token with ticker ${onlySymbol} in two languages: first in Russian, then in English. Go point by point: 1. Give links to official website, Twitter, CoinMarketCap and Coingecko. 2. Provide last price, market capitalization and 24hr volume in usd (use short scale notation). 3. Provide a clear explanation of the project in simple words, including multiple real-world use cases demonstrating its purpose and functionality. 4. Provide an overview of the project's history, including information on its founders (background, previous projects), major investors and supporters, key milestones (funding rounds, partnerships, ecosystem expansions), and any significant changes in tokenomics or governance. 5. Compare project with at least three similar projects, highlighting its competitive advantages, unique features and potential weaknesses compared to others. 6. Provide a deep dive into token’s technology, utility, tokenomics and its market potential based on current trends and adoption. `
            )}&hints=search`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconInfoCircle size="1.2rem" />
          </ActionIcon>
        </Group>
      </Center>
    </>
  );
};
