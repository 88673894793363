import { Fragment, useState, useMemo } from "react";
import {
  IconPlus,
  IconCirclePlus,
  IconCircleMinus,
  IconReload,
  IconCaretDown,
  IconCaretUp,
  IconCheck,
  IconChecks,
  IconBug,
} from "@tabler/icons-react";
import {
  Avatar,
  Box,
  Button,
  Group,
  Text,
  UnstyledButton,
  Tooltip,
} from "@mantine/core";

import { Strategies } from "../config";
import { insertItemToArray } from "../../../common/helpers";

const SHOW_MORE_THRESHOLD = 6;

const StrategyButton = ({ type, openModal, isFake, strategy, disable }) => {
  let color;
  if (isFake) color = "gray";
  else if (
    strategy.isUsed &&
    (!strategy.params?.sellAmount || strategy.params?.sellAmount === 100)
  )
    color = "teal";
  else if (type === "buy") color = "teal";
  else if (type === "sell") color = "red";
  else if (type === "repeat") color = "violet";

  let Icon;
  // if (
  //   strategy.isUsed &&
  //   (!strategy.params.sellAmount || strategy.params.sellAmount === 100)
  // )
  //   Icon = IconChecks;
  if (strategy.isUsed) Icon = IconCheck;
  else if (strategy.hasError) Icon = IconBug;
  else if (type === "buy") Icon = IconCirclePlus;
  else if (type === "sell") Icon = IconCircleMinus;
  else if (type === "repeat") Icon = IconReload;

  const button = (
    <UnstyledButton key={strategy?.id} onClick={openModal} disabled={disable}>
      <Group>
        <Avatar size={40} color={color}>
          <Icon />
        </Avatar>
        <div>
          <Text fw={700} size="sm">
            {strategy?.title || strategy?.name}
          </Text>
          <Text size="sm">
            {Strategies[strategy?.name]?.paramsTitle(strategy) ||
              strategy?.name}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );

  if (strategy.hasError) {
    return <Tooltip label={strategy.errorMessage}>{button}</Tooltip>;
  } else {
    return button;
  }
};

export function StrategyPickerBoard({
  symbol,
  openModal,
  isFake,
  buyStrategies = [],
  sellStrategies = [],
  repeatStrategies = [],
  disableButtons,
  children,
}) {
  const [showAll, setShowAll] = useState(false);

  // if (symbol !== "TUTUSDT") return null;

  // todo: maybe you can add type to model, in such case be sure unknown strategies are handled
  const allStrategies = useMemo(() => {
    let buy = buyStrategies.map((strategy) => ({ ...strategy, type: "buy" }));
    let sell = sellStrategies.map((strategy) => ({
      ...strategy,
      type: "sell",
    }));
    let repeat = repeatStrategies.map((strategy) => ({
      ...strategy,
      type: "repeat",
    }));

    let usedOrWithErrorSell = [];
    let otherSell = [];
    for (let i = 0; i < sell.length; i++) {
      if (sell[i].isUsed || sell[i].hasError) usedOrWithErrorSell.push(sell[i]);
      else otherSell.push(sell[i]);
    }

    const all = [...buy, ...usedOrWithErrorSell, ...otherSell, ...repeat];

    // console.log("all", all);

    return all;
  }, [buyStrategies, sellStrategies, repeatStrategies]);

  const cut = showAll
    ? allStrategies
    : allStrategies.slice(0, SHOW_MORE_THRESHOLD);

  return (
    <Fragment>
      {buyStrategies?.length ||
      sellStrategies?.length ||
      repeatStrategies.length ? (
        <Fragment>
          {cut.map((strategy) => {
            return (
              <Fragment key={strategy.id}>
                <StrategyButton
                  key={strategy.id}
                  type={strategy.type}
                  openModal={openModal}
                  isFake={isFake}
                  strategy={strategy}
                  disable={disableButtons}
                />
                {strategy?.params?.and && <b>+</b>}
              </Fragment>
            );
          })}
          {/*{buyStrategies.map((strategy) => {*/}
          {/*  return (*/}
          {/*    <Fragment key={strategy.id}>*/}
          {/*      <StrategyButton*/}
          {/*        key={strategy.id}*/}
          {/*        type="buy"*/}
          {/*        openModal={openModal}*/}
          {/*        isFake={isFake}*/}
          {/*        strategy={strategy}*/}
          {/*        disable={disableButtons}*/}
          {/*      />*/}
          {/*      {strategy?.params?.and && <b>+</b>}*/}
          {/*    </Fragment>*/}
          {/*  );*/}
          {/*})}*/}
          {/*{sellStrategies.map((strategy) => {*/}
          {/*  return (*/}
          {/*    <Fragment key={strategy.id}>*/}
          {/*      <StrategyButton*/}
          {/*        key={strategy.id}*/}
          {/*        type="sell"*/}
          {/*        openModal={openModal}*/}
          {/*        isFake={isFake}*/}
          {/*        strategy={strategy}*/}
          {/*        disable={disableButtons}*/}
          {/*      />*/}
          {/*      {strategy?.params?.and && <b>+</b>}*/}
          {/*    </Fragment>*/}
          {/*  );*/}
          {/*})}*/}
          {/*{repeatStrategies.map((strategy) => {*/}
          {/*  return (*/}
          {/*    <Fragment key={strategy.id}>*/}
          {/*      <StrategyButton*/}
          {/*        key={strategy.id}*/}
          {/*        type="repeat"*/}
          {/*        openModal={openModal}*/}
          {/*        isFake={isFake}*/}
          {/*        strategy={strategy}*/}
          {/*        disable={disableButtons}*/}
          {/*      />*/}
          {/*    </Fragment>*/}
          {/*  );*/}
          {/*})}*/}
        </Fragment>
      ) : (
        <Fragment>
          <Button
            variant="light"
            color={isFake ? "gray" : "teal"}
            onClick={openModal}
            leftIcon={<IconPlus />}
          >
            Strategy
          </Button>
        </Fragment>
      )}
      {allStrategies?.length > SHOW_MORE_THRESHOLD && !showAll && (
        <Button
          variant="subtle"
          color={isFake ? "gray" : "teal"}
          onClick={() => setShowAll(true)}
          leftIcon={<IconCaretDown />}
        >
          show +{allStrategies.length - SHOW_MORE_THRESHOLD}
        </Button>
      )}
      {allStrategies?.length > SHOW_MORE_THRESHOLD && showAll && (
        <Button
          variant="subtle"
          color={isFake ? "gray" : "teal"}
          onClick={() => setShowAll(false)}
          leftIcon={<IconCaretUp />}
        >
          show less
        </Button>
      )}
      {/* other auxiliary buttons */}
      {children}
    </Fragment>
  );
}
