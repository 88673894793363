import React, { useState, useMemo, Fragment } from "react";
import { Grid, Center, Group, Text, SegmentedControl } from "@mantine/core";
import { useSelector } from "react-redux";

import NewOrder, { NewOrderShort } from "./../Trade/NewOrder";

export default function Starred() {
  const [showType, setShowType] = useState("short");

  const starred = useSelector((state) => state.starred);

  let sortedArr = useMemo(() => {
    if (!starred || !starred?.tokens || !Object.keys(starred?.tokens).length)
      return [];
    return Object.values(starred.tokens).sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    );
  }, [starred]);

  // console.log(starred?.tokens);

  if (!sortedArr.length) return null;
  // return (
  //   <Grid.Col lg={12}>
  //     <Center>
  //       <b>No starred projects</b>
  //     </Center>
  //   </Grid.Col>
  // );

  return (
    <Grid>
      <Grid.Col lg={12}>
        <Center>
          <Group>
            <SegmentedControl
              value={showType}
              onChange={(value) => setShowType(value)}
              transitionDuration={0}
              data={[
                { label: "Full", value: "full" },
                { label: "Short", value: "short" },
              ]}
            />
            <Text>
              <b>{sortedArr.length} token(s)</b>
            </Text>
          </Group>
        </Center>
      </Grid.Col>
      {showType === "full" &&
        sortedArr.map(({ symbol, platform, created }) => {
          return (
            <Grid.Col lg={6} key={symbol}>
              <NewOrder initSymbol={symbol} initPlatform={platform} />
            </Grid.Col>
          );
        })}
      {showType === "short" &&
        sortedArr.map(({ symbol, platform, created }) => {
          return (
            <Grid.Col lg={4} md={6} sm={6} xs={12} key={symbol}>
              <NewOrderShort initSymbol={symbol} initPlatform={platform} />
            </Grid.Col>
          );
        })}
    </Grid>
  );
}
