import { IconPlus, IconX } from "@tabler/icons-react";
import {
  CopyButton,
  Button,
  Grid,
  Input,
  Switch,
  Box,
  Group,
} from "@mantine/core";
import { useSelector } from "react-redux";
import AmountPercentPicker from "../AmountPercentPicker";

function BuyOnPrice({ symbol, strategy, updateStrategy, removeStrategy }) {
  let currentPrice = useSelector((state) => {
    return state.currentPrice[`${symbol.replace("USDT", "")}USDT`];
  });
  currentPrice = currentPrice || 0;

  return (
    <Grid>
      <Grid.Col
        xl={3}
        lg={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          // color="pink"
          onClick={() => removeStrategy(strategy)}
        >
          Buy On ($)
        </Button>
      </Grid.Col>
      <Grid.Col xl={9} lg={12}>
        <Grid>
          <Grid.Col xl={8} lg={12}>
            <Group>
              <Input
                w="7rem"
                label="Down $"
                placeholder="Down $"
                value={strategy.params.down}
                onChange={(e) =>
                  updateStrategy({
                    ...strategy,
                    params: { ...strategy.params, down: e.target.value },
                  })
                }
              />
              <Input
                w="7rem"
                label="Up $"
                placeholder="Up $"
                value={strategy.params.up}
                onChange={(e) =>
                  updateStrategy({
                    ...strategy,
                    params: { ...strategy.params, up: e.target.value },
                  })
                }
              />
              <CopyButton value={currentPrice}>
                {({ copied, copy }) => (
                  <Button variant="white" color="dark" onClick={copy}>
                    {currentPrice}$
                  </Button>
                )}
              </CopyButton>
            </Group>
          </Grid.Col>
          <Grid.Col
            xl={4}
            lg={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingBottom: "1rem",
            }}
          >
            <Switch
              label={<b>AND</b>}
              checked={strategy.params.and}
              onClick={(e) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, and: e.target.checked },
                })
              }
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

BuyOnPrice.type = "buy";
BuyOnPrice.color = "pink";
BuyOnPrice.model = {
  name: "buy_on_price",
  title: "Buy on price",
  params: { up: "", down: "", sellAmount: 100 },
};
BuyOnPrice.paramsTitle = (strategy) => {
  if (strategy.params.down !== "" && strategy.params.up !== "") {
    return `${strategy.params.down} - ${strategy.params.up}`;
  } else if (strategy.params.down !== "") {
    return `buy if <= ${strategy.params.down}$`;
  } else if (strategy.params.up !== "") {
    return `buy if >= ${strategy.params.up}$`;
  } else {
    return `-`;
  }
};
BuyOnPrice.Button = function (props) {
  return (
    <Button variant="default" color="pink" leftIcon={<IconPlus />} {...props}>
      Buy On ($)
    </Button>
  );
};

export default BuyOnPrice;
