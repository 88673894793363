import { IconPlus, IconX } from "@tabler/icons-react";
import { Text, Button, Grid, SegmentedControl, Switch } from "@mantine/core";
import AmountPercentPicker from "../AmountPercentPicker";

function FlexCross({ strategy, updateStrategy, removeStrategy }) {
  return (
    <Grid>
      <Grid.Col
        xl={3}
        lg={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          // color="violet"
          onClick={() => removeStrategy(strategy)}
        >
          F.Cross
        </Button>
      </Grid.Col>
      <Grid.Col xl={9} lg={12}>
        <Grid>
          <Grid.Col xl={8} lg={12}>
            <SegmentedControl
              value={strategy.params.interval}
              onChange={(value) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, interval: value },
                })
              }
              data={[
                { value: "1m", label: "1m" },
                { value: "3m", label: "3m" },
                { value: "5m", label: "5m" },
                { value: "15m", label: "15m" },
                { value: "1h", label: "1h" },
                { value: "4h", label: "4h" },
                { value: "1d", label: "1d" },
                { value: "1w", label: "1w" },
              ]}
            />
          </Grid.Col>
          <Grid.Col
            xl={2}
            lg={12}
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <AmountPercentPicker
              value={strategy.params.sellAmount}
              onChange={(value) => {
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, sellAmount: value },
                });
              }}
            />
          </Grid.Col>
          <Grid.Col
            xl={2}
            lg={12}
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "flex-end",
              paddingBottom: "1rem",
            }}
          >
            <Switch
              label={<b>AND</b>}
              checked={strategy.params.and}
              onClick={(e) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, and: e.target.checked },
                })
              }
            />
          </Grid.Col>
          <Grid.Col xl={12}>
            <SegmentedControl
              value={strategy.params.cross}
              onChange={(value) =>
                updateStrategy({
                  ...strategy,
                  params: { ...strategy.params, cross: value },
                })
              }
              data={[
                { value: "3", label: "1x3" },
                { value: "7", label: "1x7" },
                { value: "15", label: "1x15" },
              ]}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

FlexCross.type = "sell";
FlexCross.color = "violet";
FlexCross.model = {
  name: "flex_cross",
  title: "Flex cross",
  // algorithm: "flex_cross",
  params: {
    interval: "1m",
    cross: "3",
    sellAmount: 100,
  },
};
FlexCross.paramsTitle = (strategy) => {
  return `${strategy.params.interval}, 1x${strategy.params.cross} | ${
    strategy.params.sellAmount || 100
  }%`;
};

FlexCross.Button = function (props) {
  return (
    <Button variant="default" color="violet" leftIcon={<IconPlus />} {...props}>
      F.Cross
    </Button>
  );
};

export default FlexCross;
