import { Center, Grid, Text, Title, ActionIcon } from "@mantine/core";
import { IconStarFilled } from "@tabler/icons-react";

import Table, { TH } from "../../common/components/Table";
import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { toggleStarred } from "../../common/data/starred/redux";
import { useDispatch, useSelector } from "react-redux";
import { toK } from "../../common/helpers";
import { Buttons, Platform, Symbol, SymbolTH } from "../common";

export default function DowntrendTable({
  token,
  tokens,
  sortBy,
  sortDir,
  setSortBy,
  setSortDir,
}) {
  return (
    <Table>
      <Thead
        sortBy={sortBy}
        sortDir={sortDir}
        setSortBy={setSortBy}
        setSortDir={setSortDir}
      />
      <Tbody token={token} tokens={tokens} />
    </Table>
  );
}

const Thead = ({ sortBy, sortDir, setSortBy, setSortDir }) => {
  return (
    <thead>
      <tr>
        <SymbolTH />
        <TH
          title="%"
          sortBy="fall"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="Δ"
          sortBy="delta"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="dr"
          sortBy="durationInD"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="lc"
          sortBy="lcFall"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH
          title="avg"
          sortBy="speedPerCandle"
          currentSortBy={sortBy}
          currentSortDir={sortDir}
          setSortBy={setSortBy}
          setSortDir={setSortDir}
        />
        <TH title="plt" />
        <TH title="btn" />
      </tr>
    </thead>
  );
};

const Tbody = ({ token, tokens }) => {
  const dispatch = useDispatch();
  const starred = useSelector((state) => state.starred);

  return (
    <tbody>
      {(token ? [token] : tokens).map(
        ({
          symbol,
          platform,
          ll,
          fall,
          delta,
          lastPrice,
          durationInD,
          lcFall,
          speedPerCandle,
        }) => {
          let onlySymbol = symbol.replace("USDT", "");

          return (
            <tr
              key={symbol}
              style={
                {
                  // backgroundColor: starred[symbol] ? "#D1FFBD" : "white",
                }
              }
            >
              <td>
                <Symbol symbol={symbol} platform={platform} ll={ll} />
              </td>
              <td>
                <Text size="sm" weight={600} align="center" color="red.9">
                  ↓ {toK(fall)}%{" "}
                </Text>
              </td>
              <td>
                <Text
                  size="sm"
                  weight={600}
                  color={
                    delta >= 1 ? "green.9" : delta >= 0 ? "dark.9" : "red.9"
                  }
                >
                  {delta === undefined
                    ? ""
                    : delta >= 1
                    ? `+${delta}%`
                    : `${delta}%`}
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {durationInD <= 1
                    ? `${Math.ceil(durationInD * 24)}h`
                    : `${Math.ceil(durationInD)}d`}
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {toK(lcFall)}%
                </Text>
              </td>
              <td>
                <Text size="sm" weight={600} align="center">
                  {toK(speedPerCandle)}%
                </Text>
              </td>
              <td>
                <Platform symbol={symbol} platform={platform} />
              </td>
              <td>
                <Buttons symbol={symbol} platform={platform} />
              </td>
            </tr>
          );
        }
      )}
    </tbody>
  );
};
