import { Fragment } from "react";
import { Text, Tooltip } from "@mantine/core";
import { toFixed, toFixedMeaningful } from "../../common/helpers";

function SummaryRow({
  isTemp,
  action,
  amount,
  amountUsd,
  feeUsd,
  unfixedProfit,
  symbol,
  currentPrice,
  buyPrice,
}) {
  if (!currentPrice)
    return <div>* {action.toUpperCase()}: No current price</div>;

  const profit = toFixedMeaningful(
    isTemp ? unfixedProfit : amount * currentPrice - amount * buyPrice - feeUsd,
    1
  );

  return (
    <div>
      {isTemp && "* "}
      {action.toUpperCase()}: {amount} {symbol.replace("USDT", "")}
      {" | "}
      {toFixedMeaningful(currentPrice, 3)}
      {" | "}
      {toFixedMeaningful(amountUsd, 2)} USD
      {/*buy*/}
      {action === "buy" && (
        <>
          {" "}
          <span>(-{toFixedMeaningful(feeUsd, 1)})</span>
        </>
      )}
      {/*sell*/}
      {action === "sell" && (
        <>
          {" "}
          <span>({profit > 0 ? `+${profit}` : profit})</span>
        </>
      )}
    </div>
  );
}

function SummaryTooltip({
  order,
  isTemp,
  leftAmount,
  buyPrice,
  currentPrice,
  unfixedProfit = 0,
}) {
  if (!order.ops?.length) return "No buy / sell operations";

  return (
    <>
      {order.ops.map(
        ({ action, amount, amountUsd, price, profit, feeUsd }, index) => {
          const buy = [...order.ops]
            .slice(0, index + 1)
            .reverse()
            .find(({ action }) => action === "buy");
          return (
            <Fragment key={`${action}:${amount}:${buy.price}:${price}`}>
              <SummaryRow
                symbol={order.symbol}
                action={action}
                amount={amount}
                amountUsd={amountUsd}
                feeUsd={feeUsd}
                currentPrice={price}
                buyPrice={buy.price}
              />
              {order.ops[index + 1]?.action === "buy" && (
                <div>-------------</div>
              )}
            </Fragment>
          );
        }
      )}
      {leftAmount && (
        <SummaryRow
          isTemp={isTemp}
          symbol={order.symbol}
          action={"sell"}
          amount={leftAmount}
          amountUsd={leftAmount * currentPrice}
          unfixedProfit={unfixedProfit}
          currentPrice={currentPrice}
          buyPrice={buyPrice}
        />
      )}
    </>
  );
}

export default function ({
  order,
  isTemp,
  // props for unfixed sell
  unfixedProfit = 0,
  leftAmount,
  currentPrice,
  buyPrice,
}) {
  const summaryProfit = toFixed(
    (order.summary?.profit || 0) + unfixedProfit,
    1
  );
  const title = `Summary: ${summaryProfit}$`;

  let color = summaryProfit > 0 ? "teal" : "red";

  return (
    <Tooltip
      label={
        <SummaryTooltip
          isTemp={isTemp}
          order={order}
          leftAmount={leftAmount}
          buyPrice={buyPrice}
          currentPrice={currentPrice}
          unfixedProfit={unfixedProfit}
        />
      }
    >
      <Text
        align="center"
        fw={700}
        size="sm"
        style={{ cursor: "pointer" }}
        color={color}
      >
        {title}
      </Text>
    </Tooltip>
  );
}
