import { useEffect, useState } from "react";
import * as helpers from "../../common/helpers";
import { Anchor, Badge, Box, Text, Tooltip } from "@mantine/core";
import { firstLetterToUppercase } from "../../common/helpers";

export default function Symbol({ order }) {
  let { symbol, platform, market, position, isFake } = order;

  isFake = false;

  const [duration, setDuration] = useState(
    helpers.getDuration(new Date(), order.created)
  );

  useEffect(() => {
    const id = setInterval(
      () => setDuration(helpers.getDuration(new Date(), order.created)),
      1000
    );

    return () => clearInterval(id);
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      {/*<Badge mr="0.5rem" color="gray.9">*/}
      {/*  {isFake ? "F" : "R"} & {order.env === "prod" ? "P" : "L"}*/}
      {/*</Badge>*/}
      <Tooltip
        label={`${platform?.toUpperCase()} ${
          market?.toUpperCase() || "SPOT"
        } & ${order.env === "prod" ? "PROD." : "LOCAL"}`}
      >
        <Badge mr="0.5rem" color="gray.9" style={{ cursor: "pointer" }}>
          {market === "futures" ? "F" : "S"} &{" "}
          {order.env === "prod" ? "P" : "L"}
        </Badge>
      </Tooltip>

      <Text align="center" fw={700} size="sm">
        <Anchor
          style={{ textDecoration: "none" }}
          href={helpers.getTradingPageUrl(symbol, platform, market)}
          target="_blank"
          color={isFake ? "gray.9" : "teal"}
        >
          {symbol.replace("USDT", "")}
          {order.market === "futures" ? (
            <Text
              span={true}
              color={isFake ? "dark" : position === "long" ? "indigo" : "red"}
            >
              {" "}
              {/*{order.position === "short" ? "sh." : "lg."}{" "}*/}
              {order.futuresStrategy?.leverage}x
            </Text>
          ) : (
            ""
          )}
        </Anchor>
      </Text>

      <Tooltip label={`${new Date(order.created).toLocaleString()}`}>
        <Text
          ml="0.5rem"
          align="center"
          fw={700}
          size="sm"
          style={{ cursor: "pointer" }}
          c="dimmed"
        >
          {duration}
        </Text>
      </Tooltip>
    </Box>
  );
}
