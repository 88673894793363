import InstantBuy from "./InstantBuy";
import BuyOnPrice from "./BuyOnPrice";

import FlexCross from "./FlexCross";
import OnClose from "./OnClose";
import SellOnPercent from "./SellOnPercent";
import SellOnPrice from "./SellOnPrice";
import HABuy from "./HABuy";
import HASell from "./HASell";
import SellSetLong from "./SellSetLong";

import Repeat from "./Repeat";
import SellSetShort from "./SellSetShort";

export const Strategies = {
  [BuyOnPrice.model.name]: {
    component: BuyOnPrice,
    ...BuyOnPrice,
  },
  [InstantBuy.model.name]: {
    component: InstantBuy,
    ...InstantBuy,
  },
  [HABuy.model.name]: {
    component: HABuy,
    ...HABuy,
  },
  [SellOnPrice.model.name]: {
    component: SellOnPrice,
    ...SellOnPrice,
  },
  [SellOnPercent.model.name]: {
    component: SellOnPercent,
    ...SellOnPercent,
  },
  [HASell.model.name]: {
    component: HASell,
    ...HASell,
  },
  [FlexCross.model.name]: {
    component: FlexCross,
    ...FlexCross,
  },
  [OnClose.model.name]: {
    component: OnClose,
    ...OnClose,
  },
  [SellSetLong.model.name]: {
    component: SellSetLong,
    ...SellSetLong,
  },
  [SellSetShort.model.name]: {
    component: SellSetShort,
    ...SellSetShort,
  },
  [Repeat.model.name]: {
    component: Repeat,
    ...Repeat,
  },
};
